import { IWatchlistItem } from '../api/WatchlistTypes';

// REQUEST WATCHLIST
export const REQUEST_WATCHLIST_LOADING = 'REQUEST_WATCHLIST_LOADING';
export const REQUEST_WATCHLIST_SUCCESS = 'REQUEST_WATCHLIST_SUCCESS';
export const REQUEST_WATCHLIST_FAILURE = 'REQUEST_WATCHLIST_FAILURE';

interface IRequestWatchlistLoadingAction {
    type: typeof REQUEST_WATCHLIST_LOADING
}

interface IRequestWatchlistSuccessAction {
    type: typeof REQUEST_WATCHLIST_SUCCESS,
    payload: IWatchlistItem[]
}

interface IRequestWatchlistFailureAction {
    type: typeof REQUEST_WATCHLIST_FAILURE,
    payload: string
}

// UPDATE WATCHLIST
export const UPDATE_WATCHLIST_SUCCESS = 'UPDATE_WATCHLIST_SUCCESS';
export const UPDATE_WATCHLIST_FAILURE = 'UPDATE_WATCHLIST_FAILURE';

interface IUpdateWatchlistSuccessAction {
    type: typeof UPDATE_WATCHLIST_SUCCESS,
    payload: IWatchlistItem[]
}

interface IUpdateWatchlistFailureAction {
    type: typeof UPDATE_WATCHLIST_FAILURE,
    payload: string
}

// REMOVE ERROR TOAST
export const REMOVE_ERROR_TOAST = 'REMOVE_ERROR_TOAST';

interface IRemoveErrorToast {
    type: typeof REMOVE_ERROR_TOAST,
    payload: {
        id: number
    }
}

// EXPORT.
export type WatchlistTypes =
// Request watchlist.
IRequestWatchlistLoadingAction
| IRequestWatchlistSuccessAction
| IRequestWatchlistFailureAction
// Update watchlist.
| IUpdateWatchlistSuccessAction
| IUpdateWatchlistFailureAction
// Remove error toast
| IRemoveErrorToast;