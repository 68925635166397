// Package imports:
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './SearchPage.scss';
// Component imports:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';
import SearchBar from '../SearchBar/SearchBar';
import GemmaqChart from '../Common/GemmaqChart';
// Action imports:
import { requestSingleCompanyInfo } from '../../redux-store/actions/companiesActions';
// Service imports:
import { sexToGender } from '../../services/utils';
import { isUserLoggedIn } from '../../services/loginUtils';
// Type imports:
import { IVeryBasicCompanyInfo } from '../../types/api/CompaniesTypes';
import { RootState } from '../../redux-store';

type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps;

const SearchPage: React.FC<Props> = ({
    isLoggedIn,
    veryBasicCompanyInfo,
    companyDetailsMap,
    requestSingleCompanyInfo
}) => {
    const [ selectedCompany, setSelectedCompany ] = useState<IVeryBasicCompanyInfo | null>(null);
    const [ hasSetCompanyFromUrlAnchor, setHasSetCompanyFromUrlAnchor ] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const companyName = urlParams.get('company')
        if (
            companyName
            && selectedCompany === null
            && Array.isArray(veryBasicCompanyInfo)
            && !hasSetCompanyFromUrlAnchor
        ) {
            const company = veryBasicCompanyInfo.find(company => company.name === companyName);
            if (company) {
                setHasSetCompanyFromUrlAnchor(true);
                setSelectedCompany(company)
            }
        }
        // eslint-disable-next-line
    }, [veryBasicCompanyInfo]);

    useEffect(() => {
        if (selectedCompany && companyDetailsMap[selectedCompany.name] === undefined) {
            requestSingleCompanyInfo(selectedCompany.name);
        };
    }, [ selectedCompany, requestSingleCompanyInfo, companyDetailsMap ]);

    const displayContent = () => {
        if (selectedCompany === null) {
            return (
                <div className='NoCompanySelectedText'>
                    Diversity Lens on Stock Markets
                </div>
            )
        } else {
            const singleCompany = companyDetailsMap[selectedCompany.name];
            return (
                <div className='CompanyInfoContainer'>
                    {(singleCompany === 'loading' || singleCompany === undefined) ? (
                        'Loading'
                    ) : (singleCompany instanceof Error) ? (
                        'Error'
                    ) : (
                        <>
                            <div className='CompanyHeaderInfo'>
                                <span>{selectedCompany.name}</span>
                                <a
                                    href={`/company/${selectedCompany.name}`}
                                >
                                    <FontAwesomeIcon icon={faChevronCircleRight} className='FaChevronCircleRight' />
                                    View Company Profile
                                </a>
                            </div>
                            <div className='ChartWrapper'>
                                <GemmaqChart
                                    rating={singleCompany.gemmaq_rating}
                                    boardOfDirectors={singleCompany.percentage_female_board_members}
                                    topExecutives={singleCompany.percentage_female_executives}
                                    chairman={sexToGender(singleCompany.chairman[0].gender)}
                                    ceo={sexToGender(singleCompany.ceo[0].gender)}
                                />
                            </div>
                            <div className='BottomText'>
                                {!isLoggedIn && 'Log in and create your own gender-lens to invest directly in women empowerment.'}
                            </div>
                        </>
                    )}
                </div>
            );
        }
    }

    return (
        <div id='SearchPage'>
            <div className='LogoSection'>
                <img
                    src={require('../../assets/gemmaqlogowithname.png')}
                    alt='Gemmaq Logo'
                />
            </div>
            <div className='SearchBarSection'>
                <div className='SearchBarContainer'>
                    <SearchBar
                        isBig
                        isBookmarkable
                        onSelectOverride={(veryBasicCompanyInfo) => {
                            window.history.pushState(null, '', `?company=${veryBasicCompanyInfo.name}`);
                            setSelectedCompany(veryBasicCompanyInfo);
                        }}
                    />
                </div>
            </div>
            <div className='ContentContainer'>
                {displayContent()}
            </div>
        </div>
    )
}

function mapStateToProps({ companiesReducer, loginReducer }: RootState) {
    const { companyDetailsMap, veryBasicCompanyInfo } = companiesReducer;
    const { accessToken, expiresAt_ms, idToken } = loginReducer
    const isLoggedIn = isUserLoggedIn(accessToken, idToken, expiresAt_ms);
    return {
        isLoggedIn,
        veryBasicCompanyInfo,
        companyDetailsMap
    }
}

const connector = connect(mapStateToProps, { requestSingleCompanyInfo });
export default connector(SearchPage);