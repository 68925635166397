import { IErrorToast, IWatchlistItem } from '../../types/api/WatchlistTypes';
import { REMOVE_ERROR_TOAST, REQUEST_WATCHLIST_FAILURE, REQUEST_WATCHLIST_LOADING, REQUEST_WATCHLIST_SUCCESS, UPDATE_WATCHLIST_FAILURE, UPDATE_WATCHLIST_SUCCESS, WatchlistTypes } from '../../types/redux/WatchlistTypes';

export interface IWatchlistState {
    watchlist: IWatchlistItem[] | 'loading' | null | Error,
    updateWatchlistError: Error | null,
    errorToasts: IErrorToast[]
}

const initialState: IWatchlistState = {
    watchlist: null,
    updateWatchlistError: null,
    errorToasts: []
}

let ID = 1;
const getId = () => ID++;

export default function(state: IWatchlistState = initialState, action: WatchlistTypes): IWatchlistState {
    switch(action.type) {
        // REQUEST WATCHLIST
        case REQUEST_WATCHLIST_LOADING: {
            const newState: IWatchlistState = {
                ...state,
                watchlist: 'loading'
            }
            return newState;
        }
        case UPDATE_WATCHLIST_SUCCESS:
        case REQUEST_WATCHLIST_SUCCESS:
        {
            const watchlist = action.payload;
            const newState: IWatchlistState = {
                ...state,
                watchlist
            };
            return newState;
        }
        case REQUEST_WATCHLIST_FAILURE: {
            const error = action.payload;
            const newState: IWatchlistState = {
                ...state,
                watchlist: new Error(error),
                errorToasts: state.errorToasts.concat([{
                    id: getId(),
                    header: 'Error fetching Watchlist',
                    error: new Error(error)
                }])
            };
            return newState;
        }
        case UPDATE_WATCHLIST_FAILURE: {
            const error = action.payload;
            const newState: IWatchlistState = {
                ...state,
                updateWatchlistError: new Error(error),
                errorToasts: state.errorToasts.concat([{
                    id: getId(),
                    header: 'Error updating Watchlist',
                    error: new Error(error)
                }])
            };
            return newState;
        }
        // REMOVE ERROR TOAST
        case REMOVE_ERROR_TOAST: {
            const { id } = action.payload;
            const newState: IWatchlistState = {
                ...state,
                errorToasts: state.errorToasts.filter(toast => toast.id !== id)
            };
            return newState;
        }
        default:
            return state;
    }
}
