// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Highcharts from 'highcharts';
// Style imports:
import './GemmaqChart.scss';

interface IOwnProps {
    // Values for data.
    rating: number,
    boardOfDirectors: number, // 0-100
    topExecutives: number, // 0-100
    chairman: 'Woman' | 'Man' | 'Not Set' | number,
    ceo: 'Woman' | 'Man' | 'Not Set' | number,
    // Custom values.
    precise?: boolean
}

type Props = IOwnProps;

const CHART_COLORS = ['#4353D8', '#5A8EEE', '#55CCA1', '#FBD825','#FAB530','#EB5CA4'];
//                       0-9%      10-19%     20-29%     30-39%    40-49%     50%+

const GemmaqChart: React.FC<Props> = ({
    rating,
    boardOfDirectors,
    topExecutives,
    chairman,
    ceo,
    precise
}) => {
    // Refs:
    const chartElementRef = useRef<HTMLDivElement | null>(null);
    const isFirstRenderRef = useRef<boolean>(true);

    const [ forcedReRender, setForcedReRender] = useState(1);
    console.log(forcedReRender);
    
    // Add window resize rerender listener.
    // TODO CHANGE TO css resize sensor
    useEffect(() => {
        const chartElementDiv = chartElementRef.current;
        if (chartElementDiv === null) return;
        window.addEventListener('resize', reRenderChart);
        return () => window.removeEventListener('resize', reRenderChart);
    }, []);

    useEffect(() => {
        renderChartIntoDiv();
    });

    // Memo variables.
    // Colors:
    const ceoColor = useMemo(() => {
        return (typeof ceo === 'number')
            ? (CHART_COLORS[Math.min(Math.floor(ceo / 10), 5)])
            : (ceo === 'Man')
            ? CHART_COLORS[0]
            : (ceo === 'Woman')
            ? CHART_COLORS[CHART_COLORS.length - 1]
            : '#888888';
    }, [ ceo ]);
    const chairColor = useMemo(() => {
        return (typeof chairman === 'number')
            ? CHART_COLORS[Math.min(Math.floor(chairman / 10), 5)]
            : (chairman === 'Man')
            ? CHART_COLORS[0]
            : (chairman === 'Woman')
            ? CHART_COLORS[CHART_COLORS.length - 1]
            : '#888888';
    }, [ chairman ]);
    const topExecutivesColor = useMemo(() => {
        return CHART_COLORS[Math.min(Math.floor(topExecutives / 10), 5)]
    }, [ topExecutives ]);
    const boardOfDirectorsColor = useMemo(() => {
        return CHART_COLORS[Math.min(Math.floor(boardOfDirectors / 10), 5)];
    }, [ boardOfDirectors ]);
    // Descriptions:
    const ceoDescription =  useMemo(() => {
        return ((typeof ceo === 'string')
            ? ceo
            : (precise)
            ? (Math.round(ceo * 10) / 10 + '%')
            : (Math.round(ceo) + '%'));
    }, [ ceo, precise ]);
    const chairmanDescription = useMemo(() => {
        return ((typeof chairman === 'string')
            ? chairman
            : (precise)
            ? (Math.round(chairman * 10) / 10 + '%')
            : (Math.round(chairman) + '%'));
    }, [ chairman, precise ]);
    const topExecutivesDescription =  useMemo(() => {
        return ((precise)
            ? (Math.round(topExecutives * 10) / 10 + '% Women')
            : Math.round(topExecutives) + '% Women');
    }, [ topExecutives, precise ]);
    const boardOfDirectorsDescription =  useMemo(() => {
        return ((precise)
            ? (Math.round(boardOfDirectors * 10) / 10 + '% Women')
            : Math.round(boardOfDirectors) + '% Women');
    }, [ boardOfDirectors, precise ]);
    

    // Force rerender with state change.
    const reRenderChart = () => {
        setForcedReRender((value) => value + 1);
    }

    const renderChartIntoDiv = () => {
        const renderElement = chartElementRef.current;
        if (renderElement === null) return;

        const ratingRound = Math.round(rating * 10) / 10;

        const height = (window.matchMedia('(min-width: 1200px)').matches) ? 302 : 262;

        Highcharts.chart(renderElement, {
            chart: {
                type: 'pie',
                height: height,
                reflow: true
            },
            title: {
                text: ratingRound.toString(),
                x: 0,
                floating: true,
                y: height * 0.50,
                style: {
                    fontSize: 0.15 * height + 'px',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif'
                }
            },
            subtitle: {},
            //@ts-ignore
            series: [{
                animation: (isFirstRenderRef.current),
                // animation: true,
                minPointSize: 10,
                innerSize: '40%',
                name: '',
                data: [{
                    y: 1,
                    color: chairColor
                }, {
                    y: 1,
                    color: boardOfDirectorsColor
                }, {
                    y: 1,
                    color: topExecutivesColor
                }, {
                    y: 1,
                    color: ceoColor
                }]
            }],
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    size: '90%',
                    center: ['50%', '50%'],
                    dataLabels: []
                }
            }
        }, () => {});
        // Has rendered once, no longer first render.
        if (isFirstRenderRef.current) isFirstRenderRef.current = false;
    }

    const displayText = () => {
        const colorData = [{
            id: 'Ceo',
            title: 'CEO',
            value: ceoDescription,
            color: ceoColor
        }, {
            id: 'Chair',
            title: 'Chair',
            value: chairmanDescription,
            color: chairColor
        }, {
            id: 'Top',
            title: 'Top Executives',
            value: topExecutivesDescription,
            color: topExecutivesColor
        }, {
            id: 'Bod',
            title: 'Board of Directors',
            value: boardOfDirectorsDescription,
            color: boardOfDirectorsColor
        }]

        return (
            <div className='TextContainer'>
                {colorData.map(({ id, title, value, color }, index) => (
                    <div
                        key={index}
                        className={`DataWrapper ${id}-Wrapper`}
                    >
                        <span className='Title'>{title}</span>
                        <div className='ColorValueContainer'>
                            <span className='Value'>{value}</span>
                            <div
                                className='ColorBlock'
                                style={{ backgroundColor: color }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className='GemmaqChartViewport'>
            <div className='GemmaqChart'>
                <div
                    className='ChartElement'
                    ref={chartElementRef}
                />
                {displayText()}
            </div>
        </div>
    );
}

export default GemmaqChart;
