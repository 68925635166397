import { INewsCard } from '../../types/api/NewsFeedTypes';
import {
    ADD_NEWS_CARDS_SUCCESS,
    ADD_NEWS_CARDS_FAILURE,
    NewsFeedActions
} from '../../types/redux/NewsFeedTypes';

export interface INewsFeedState {
    totalCount: number | null,
    totalFetched: number,
    nextIndexToFetch: number,
    newsCards: Array<INewsCard>,
    error: string | null
}

const initialState: INewsFeedState = {
    totalCount: null,
    totalFetched: 0,
    nextIndexToFetch: 0,
    newsCards: [],
    error: null
}

export default function(state: INewsFeedState = initialState, action: NewsFeedActions): INewsFeedState {
    switch(action.type) {
        case ADD_NEWS_CARDS_SUCCESS: {
            const { totalCount, feedItems } = action.payload;
            const fetchedThisBatch = feedItems.length;
            const newsCardsWithNewBatch = state.newsCards.concat(feedItems);
            const uniqueNewsCards: Array<INewsCard> = [];
            for (const newCard of newsCardsWithNewBatch) {
                const cardExists = uniqueNewsCards.find(card => card.link === newCard.link)
                if (cardExists === undefined) {
                    uniqueNewsCards.push(newCard);
                }
            }

            uniqueNewsCards.sort((a, b) => {
                if (a.newsdate < b.newsdate) return 1;
                if (a.newsdate > b.newsdate) return -1;
                return 0;
            });
            const newState: INewsFeedState = {
                ...state,
                totalCount,
                totalFetched: state.totalFetched + fetchedThisBatch,
                nextIndexToFetch: state.nextIndexToFetch + fetchedThisBatch,
                newsCards: uniqueNewsCards
            };
            return newState;
        }
        case ADD_NEWS_CARDS_FAILURE: {
            const error = action.payload;
            const newState: INewsFeedState = {
                ...state,
                error
            };
            return newState;
        }
        default:
            return state;
    }
}