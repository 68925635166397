// Package imports:
import React from 'react';
// Style imports:
import './Methodology.scss';
// Component imports:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Image imports:
const colorMeaningImage = require('../../assets/ColorMeaning.png');
const ratingImage2 = require('../../assets/methodology/2.PNG');
const ratingImage3 = require('../../assets/methodology/3.PNG');
const ratingImage4 = require('../../assets/methodology/4.PNG');
const ratingImage5 = require('../../assets/methodology/5.PNG');
const ratingImage6 = require('../../assets/methodology/6.PNG');
const ratingImage7 = require('../../assets/methodology/7.PNG');
const ratingImage8 = require('../../assets/methodology/8.PNG');
const ratingImage9 = require('../../assets/methodology/9.PNG');
const ratingImage10 = require('../../assets/methodology/10.PNG');

class Methodology extends React.Component {
    render () {
        const ratingImages = [
            ratingImage2,
            ratingImage3,
            ratingImage4,
            ratingImage5,
            ratingImage6,
            ratingImage7,
            ratingImage8,
            ratingImage9,
            ratingImage10
        ];

        return (
            <Container id='Methodology'>
                <Row>
                    <Col>
                        <div className='PageHorizontalLine'></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 className='PageHeader'>The GEMMAQ Gender Lens</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} xs={12}>
                        <p>
                            GEMMAQ rates companies based on gender diversity among their leadership (CEO, Executives, Chair, Board) to show women's real economic impact in the market, and color codes gender diversity to visualize the delegation of power.  We look at each person’s gender identity and pronouns used (she/her, he/him, &amp; gender neutral pronouns such as they/them), when analyzing &amp; rating companies. GEMMAQ stands for <strong>G</strong>ender <strong>E</strong>quality <strong>M</strong>easure <strong>M</strong>onthly <strong>A</strong>utomated <strong>Q</strong>uotation and - as the name implies - will be automatically updated on monthly basis.&nbsp;
                        </p>
                    </Col>
                    <Col xl={6} xs={12}>
                        <p>
                            <img
                                src={colorMeaningImage}
                                alt='Percent % To Color'
                                className='ColorMeaningImage'
                            />
                            <br className='ColorMeaningBreakpoint' />
                            The positions of CEO and Chair are not added to the grade average unless held by a woman. The leadership variables are equally weighted to give a grade on the scale 0-10, with 10 being the highest (best) possible grade.  Here are examples of companies rated from 2 - 10, where brighter colors mean a better gender balance; pink (50/50), orange (40/60), yellow (30/70).
                        </p>
                    </Col>
                </Row>
                <Row className='RatingImageWrapper'>
                    {
                        ratingImages.map((ratingImage, index) => (
                            <Col
                                key={index}
                                xl={4}
                                md={6}
                                xs={12}
                            >
                                <img
                                    className='RatingImage'
                                    src={ratingImage}
                                    alt={`GEMMAQ Rating ${index + 2}`}
                                />
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Col>
                        <p className='UnderParagraph'>
                            We have built a unique <a href='https://www.gemmaqratings.com/'>Gender Lens for Global Markets</a>, in partnership with<a href='https://kodi.is/'> Kodi Software Brewery in Iceland</a>, showcasing (to begin with) the GEMMAQ ratings for all the Fortune 500 companies. Our Gender Lens platform shows current ratings for these companies &amp; annual changes among their corporate leadership - to visualize the real impact women have on the market economy. Here &amp; Now.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Our Gender data &amp; GEMMAQ Ratings are accessible through a RESTful API.
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Methodology;