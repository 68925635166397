// Package imports:
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './BiggestCompaniesList.scss';
// Component imports:
import { CustomToggle } from './CustomFilterComponents';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import WatchlistIcon from '../../Common/WatchlistIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronDoubleLeft, faChevronRight, faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
// Service imports:
import { roundToHalfs } from '../../../services/utils';
// Action imports:
// Type imports:
import { RootState } from '../../../redux-store';
import { ICompanyInfo } from '../../../types/api/CompaniesTypes';
import { Sectors } from '../../../types/Types';

// Types for sort:
type SortTypes = 'rating' | 'alphabetical';
type PropertyTypes = 'gemmaq_rating' | 'company'
// const SORT_TYPES: Array<SortTypes> = ['rating', 'alphabetical'];
type SortTypeToPropertyMap = {
    [T in SortTypes]: PropertyTypes
}
const sortTypeToPropertyMap: SortTypeToPropertyMap = {
    'rating': 'gemmaq_rating',
    'alphabetical': 'company'
}

// Types for filter:
const SECTOR_FILTER_TYPES: Array<Sectors> = ['Materials', 'Health Care', 'Business Services', 'Technology', 'Motor Vehicles & Parts', 'Energy', 'Financials', 'Industrials', 'Chemicals', 'Transportation', 'Retail', 'Food, Beverages & Tobacco', 'Wholesalers', 'Aerospace & Defense', 'Telecommunications', 'Household Products', 'Hotels, Restaurants & Leisure', 'Engineering & Construction', 'Media', 'Engineering', 'Apparel'];

// Prop and state declarations:
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps;
interface IState {
    currentPageIndex: number, // 0 indexed
    sortBy: SortTypes,
    reverseSort: boolean,
    sectorFilterBy: Sectors | null,
}

const DESIRED_NUMBER_OF_PAGES = 5;
const NUMBER_OF_COMPANIES_PER_PAGE = 20;
const totalNumberOfPages = (lengthOfData: number) => Math.ceil(lengthOfData / NUMBER_OF_COMPANIES_PER_PAGE);

class BiggestCompaniesList extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentPageIndex: 0,
            sortBy: 'alphabetical',
            reverseSort: false,
            sectorFilterBy: null
        };
    }

    displayTableRows = (companyDataList: Array<ICompanyInfo>) => {
        const { currentPageIndex } = this.state;

        // Paginating.
        const startIndex = currentPageIndex * NUMBER_OF_COMPANIES_PER_PAGE;
        const endIndex = startIndex + NUMBER_OF_COMPANIES_PER_PAGE;
        const companiesInCurrentPage = companyDataList.slice(startIndex, endIndex);

        // Turn to JSX.
        return companiesInCurrentPage.map((companyData, index) => {
            let companyRatingChange = roundToHalfs(companyData.rating_change);
            const { ratingChange, ratingChangeClassName } = (companyRatingChange < 0)
                ? {
                    ratingChange: companyRatingChange,
                    ratingChangeClassName: 'Negative'
                } :
                (companyData.rating_change > 0) ? {
                    ratingChange: '+' + companyRatingChange,
                    ratingChangeClassName: 'Positive'
                } : {
                    ratingChange: '+' + companyRatingChange,
                    ratingChangeClassName: undefined
                }
            return (
                <tr key={index}>
                    <td className='TextColumn TableTextLeft'>
                        <WatchlistIcon
                            companyName={companyData.company}
                            companyMnemonic={companyData.mnemonic}
                        />
                        <a href={`/company/${companyData.company}`}>
                            {companyData.company}
                        </a>
                    </td>
                    <td className='TableTextRight'>
                        {Math.round(companyData.gemmaq_rating * 2) / 2}
                        <span className={ratingChangeClassName}>{ratingChange}</span>
                    </td>
                </tr>
            );
        });
    }

    displayPagination = (companyData: Array<ICompanyInfo>) => {
        const { currentPageIndex } = this.state;
        // Empty check.
        if (this.props.error !== null || companyData.length === 0) return null;
        // Helper variables!
        const numberOfPages = totalNumberOfPages(companyData.length);
        const numberOfPagesToDisplay = Math.min(DESIRED_NUMBER_OF_PAGES, numberOfPages);
        let numberOfPrevPages = Math.floor((numberOfPagesToDisplay - 1) / 2);
        let numberOfNextPages = Math.ceil((numberOfPagesToDisplay - 1) / 2);
        const currentPageNumber = currentPageIndex + 1;
        // Adjust next and prev pages with edge cases.
        while (currentPageIndex < numberOfPrevPages) {
            numberOfPrevPages--;
            numberOfNextPages++;
        }
        while (currentPageIndex > (numberOfPages - numberOfNextPages - 1)) {
            numberOfPrevPages++;
            numberOfNextPages--;
        }
        // Find the pagination items to display:
        const numberedPaginationCells = [<div key={currentPageNumber} className='Active'>{currentPageNumber}</div>];
        // Add prev pagination items.
        for (let i = 1; i <= numberOfPrevPages; i++) {
            numberedPaginationCells.unshift(
                <div key={currentPageNumber - i} onClick={() => this.setState({ currentPageIndex: currentPageIndex - i })}>{currentPageNumber - i}</div>
            );
        }
        // Add next pagination items.
        for (let i = 1; i <= numberOfNextPages; i++) {
            numberedPaginationCells.push(
                <div key={currentPageNumber + i} onClick={() => this.setState({ currentPageIndex: currentPageIndex + i })}>{currentPageNumber + i}</div>
            )
        }

        // Create classNames.
        const disabledIfOnFirst = (currentPageIndex === 0) ? 'Disabled' : '';
        const disabledIfOnLast = (currentPageIndex === numberOfPages - 1) ? 'Disabled' : '';

        return (
            <div className='PaginationWrapper'>
                <div
                    className={disabledIfOnFirst}
                    onClick={() => {
                        if (!disabledIfOnFirst) {
                            this.setState({ currentPageIndex: 0 });
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronDoubleLeft} />
                </div>
                <div
                    className={disabledIfOnFirst}
                    onClick={() => {
                        if (!disabledIfOnFirst) {
                            this.setState({ currentPageIndex: currentPageIndex - 1 })
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                {numberedPaginationCells}
                <div
                    className={disabledIfOnLast}
                    onClick={() => {
                        if (!disabledIfOnLast) {
                            this.setState({ currentPageIndex: currentPageIndex + 1 })
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div
                    className={disabledIfOnLast}
                    onClick={() => {
                        if (!disabledIfOnLast) {
                            this.setState({ currentPageIndex: numberOfPages - 1 })
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronDoubleRight} />
                </div>
            </div>
        );
    }

    displayTable = (companyDataList: Array<ICompanyInfo>) => {
        if (this.props.error !== null) {
            return (
                <div className='ErrorWrapper'>
                    <h3>Error retrieving data.</h3>
                </div>
            )
        }
        if (companyDataList.length === 0) {
            return (
                <div className='LoadingWrapper'>
                    <h3>Loading...</h3>
                </div>
            )
        }
        return (
            <Table>
                <tbody>
                    {this.displayTableRows(companyDataList)}
                </tbody>
            </Table>
        );
    }

    displayTableHeader = () => {
        return (
            <div className='TableHeaderWrapper'>
                <p
                    className='TableHeader FirstTableHeader'
                    onClick={() => {
                        if (this.state.sortBy === 'alphabetical') {
                            this.setState({
                                sortBy: 'alphabetical',
                                reverseSort: !this.state.reverseSort
                            });
                        } else {
                            this.setState({
                                sortBy: 'alphabetical',
                                reverseSort: false
                            });
                        }
                    }}
                >
                    Company
                </p>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id='Fortune500SectorFilter'>
                        {this.state.sectorFilterBy || 'VIEW BY SECTOR'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu id='SectorFilterMenu'>
                        <Dropdown.Item
                            className={(this.state.sectorFilterBy === null) ? 'Selected' : ''}
                            onClick={() => this.setState({
                                sectorFilterBy: null,
                                currentPageIndex: 0,
                                sortBy: 'alphabetical',
                                reverseSort: false
                            })}
                        >
                            View by Sector
                        </Dropdown.Item>
                        {
                            SECTOR_FILTER_TYPES.map((sectorString, index) => (
                                <Dropdown.Item
                                    key={index}
                                    className={(this.state.sectorFilterBy === sectorString) ? 'Selected' : ''}
                                    onClick={() => this.setState({
                                        sectorFilterBy: sectorString,
                                        currentPageIndex: 0,
                                        sortBy: 'alphabetical',
                                        reverseSort: false
                                    })}
                                >
                                    {sectorString}
                                </Dropdown.Item>
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <div className='TableHeaderJustifyRight'>
                    <p
                        className='TableHeader LastTableHeader'
                        onClick={() => {
                            if (this.state.sortBy === 'rating') {
                                this.setState({
                                    sortBy: 'rating',
                                    reverseSort: !this.state.reverseSort
                                });
                            } else {
                                this.setState({
                                    sortBy: 'rating',
                                    reverseSort: false
                                });
                            }
                        }}
                    >
                        Gemmaq Rating
                    </p>
                </div>
            </div>
        )
    }

    render() {
        const { sortBy, sectorFilterBy, reverseSort } = this.state;
        const { companiesList, error } = this.props;
        if (companiesList === null && error !== null) {
            return (
                <div className='ErrorWrapper'>
                    <h3>Error retrieving data.</h3>
                </div>
            );
        }
        if (companiesList === null || companiesList === 'loading') {
            return (
                <div className='LoadingWrapper'>
                    <h3>Loading...</h3>
                </div>
            );
        }
        // Filter.
        const companiesFilteredBySector = (sectorFilterBy === null)
            ? [...companiesList]
            : companiesList.filter(company => company.sector === sectorFilterBy);

        // Sorting.
        const sortingProperty = sortTypeToPropertyMap[sortBy];
        companiesFilteredBySector.sort((a, b) => {
            if (sortingProperty === 'gemmaq_rating') {
                // Rating is sorted in DESCENDING order.
                if (a[sortingProperty] < b[sortingProperty]) return (reverseSort) ? -1 : 1;
                if (a[sortingProperty] > b[sortingProperty]) return (reverseSort) ? 1 : -1;
                return 0;
            } else {
                // By default properties are sorted in ASCENDING order.
                if (a[sortingProperty].toLowerCase() < b[sortingProperty].toLowerCase()) return (reverseSort) ? 1 : -1;
                if (a[sortingProperty].toLowerCase() > b[sortingProperty].toLowerCase()) return (reverseSort) ? -1 : 1;
                return 0;
            }
        });

        return (
            <div id='BiggestCompaniesList'>
                {this.displayTableHeader()}
                {this.displayTable(companiesFilteredBySector)}
                {this.displayPagination(companiesFilteredBySector)}
            </div>
        );
    }
}

function mapStateToProps({ companiesReducer, watchlistReducer }: RootState) {
    const { companiesList, error } = companiesReducer;
    const { watchlist } = watchlistReducer;
    return {
        companiesList,
        error,
        watchlist
    }
}

const connector = connect(mapStateToProps);
export default connector(BiggestCompaniesList);