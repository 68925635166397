import { IWatchlistItem } from '../../types/api/WatchlistTypes';
import { Dispatch } from '../../types/redux';
import { REMOVE_ERROR_TOAST, REQUEST_WATCHLIST_FAILURE, REQUEST_WATCHLIST_LOADING, REQUEST_WATCHLIST_SUCCESS, UPDATE_WATCHLIST_FAILURE, UPDATE_WATCHLIST_SUCCESS, WatchlistTypes } from '../../types/redux/WatchlistTypes';

/* REQUEST WATCHLIST */

// Thunk function.
const requestWatchlistThunk = async (dispatch: Dispatch, idToken: string) => {
    const url = `https://wkbdf8qkff.execute-api.us-east-2.amazonaws.com/test/watchlist`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.ok) {
            const responseBody = await response.json();
            const watchlist = JSON.parse(responseBody.body);
            dispatch(requestWatchlistSuccess(watchlist));
        } else {
            dispatch(requestWatchlistFailure('Request failed'));
        }
    } catch (e) {
        dispatch(requestWatchlistFailure('Network Error'));
    }
}

// Loading function.
const requestWatchlistLoading = (): WatchlistTypes => ({
    type: REQUEST_WATCHLIST_LOADING
});

// Success function.
const requestWatchlistSuccess = (companies: Array<IWatchlistItem>): WatchlistTypes => ({
    type: REQUEST_WATCHLIST_SUCCESS,
    payload: companies
});

// Failure function.
const requestWatchlistFailure = (error: string): WatchlistTypes => ({
    type: REQUEST_WATCHLIST_FAILURE,
    payload: error
});

// Exported function.
export const requestWatchlist = (idToken: string) => {
    return (dispatch: Dispatch) => {
        dispatch(requestWatchlistLoading());
        requestWatchlistThunk(dispatch, idToken);
    }
}

/* UPDATE WATCHLIST */

// Thunk function.
const updateWatchlistThunk = async (dispatch: Dispatch, newWatchlist: IWatchlistItem[], idToken: string) => {
    const url = `https://wkbdf8qkff.execute-api.us-east-2.amazonaws.com/test/watchlist`;
    const newWatchlistMnemonics = newWatchlist.map(watchlistItem => watchlistItem.mnemonic);
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newWatchlistMnemonics)
        });
        if (response.ok) {
            dispatch(updateWatchlistSuccess(newWatchlist));
        } else {
            dispatch(updateWatchlistFailure('Request failed'));
        }
    } catch (e) {
        dispatch(updateWatchlistFailure('Network Error'));
    }
}

// Success function.
const updateWatchlistSuccess = (companies: Array<IWatchlistItem>): WatchlistTypes => ({
    type: UPDATE_WATCHLIST_SUCCESS,
    payload: companies
});

// Failure function.
const updateWatchlistFailure = (error: string): WatchlistTypes => ({
    type: UPDATE_WATCHLIST_FAILURE,
    payload: error
});

// Exported function.
export const updateWatchlist = (newWatchlist: IWatchlistItem[], idToken: string) => {
    return (dispatch: Dispatch) => {
        updateWatchlistThunk(dispatch, newWatchlist, idToken);
    }
}


/* REMOVE ERROR TOAST */

const removeErrorToastAction = (id: number): WatchlistTypes => ({
    type: REMOVE_ERROR_TOAST,
    payload: {
        id
    }
})

export const removeErrorToast =
    (idToRemove: number) =>
    (dispatch: Dispatch) =>
    dispatch(removeErrorToastAction(idToRemove));