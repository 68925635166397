// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
// Style imports:
import './SearchBar.scss';
// Component imports:
import Form from 'react-bootstrap/Form';
import WatchlistIcon from '../Common/WatchlistIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
// Action imports:
import { requestVeryBasicCompanyInfo } from '../../redux-store/actions/companiesActions';
// Type imports:
import { RootState } from '../../redux-store';
import { IVeryBasicCompanyInfo } from '../../types/api/CompaniesTypes';

type ReduxProps = ConnectedProps<typeof connector>
interface IOwnProps {
    placeholder?: string,
    onSelectOverride?: (veryBasicCompanyInfo: IVeryBasicCompanyInfo) => void
    isBookmarkable?: boolean
    isBig?: boolean
}

type Props = ReduxProps & IOwnProps;

const SearchBar: React.FC<Props> = ({
    placeholder,
    veryBasicCompanyInfo,
    isBookmarkable,
    isBig,
    onSelectOverride,
    requestVeryBasicCompanyInfo
}) => {
    // States:
    const [ searchBarValue, setSearchBarValue ] = useState('');
    const [ isFocused, setIsFocused ] = useState(false);
    // Refs:
    const searchBarInputRef = useRef<HTMLInputElement | null>(null);
    const searchBarParentRef = useRef<HTMLDivElement | null>(null);
    const resultLinkArrayRef = useRef<(HTMLAnchorElement | null)[]>([]);
    const showResultsRef = useRef<boolean>(false);
    const preventClickRef = useRef<boolean>(false);

    // Memos:
    const results = useMemo(() => {
        if (!Array.isArray(veryBasicCompanyInfo) || searchBarValue.length === 0) return null;
        return veryBasicCompanyInfo.filter(company => (
            company.name.toLowerCase().includes(searchBarValue)
            || company.mnemonic.toLowerCase().includes(searchBarValue)
        )).slice(0,6);
    }, [ searchBarValue, veryBasicCompanyInfo ]);

    const showResults = useMemo(() => {
        const showResults = isFocused && (results?.length ?? 0) > 0;
        showResultsRef.current = showResults;
        return showResults
    }, [ isFocused, results ]);


    // Effects:
    useEffect(() => {
        if (veryBasicCompanyInfo === null) requestVeryBasicCompanyInfo();
    }, [ veryBasicCompanyInfo, requestVeryBasicCompanyInfo ]);

    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            // Fetch refs.
            const showResults = showResultsRef.current;
            const resultLinkArray = resultLinkArrayRef.current;
            // Should only work if the repsonse window is open.
            if (showResults) {
                const currentFocusedElement = document.activeElement;
                // Down arrow = go to next (should be like tab)
                if (e.key === 'Escape') {
                    searchBarInputRef.current?.blur();
                    setIsFocused(false);
                }
                if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    // If focus is on search field input, move focus to 1st element in list.
                    if (currentFocusedElement === searchBarInputRef.current) {
                        resultLinkArray[0]?.focus();
                        return;
                    }
                    // If focus is on element in list, move to next. (we omit last element, since downarrow has no effect on it)
                    for (let i = 0; i < resultLinkArray.length; i++) {
                        // Edge case: if on last, focus back on search field.
                        if (i === (resultLinkArray.length - 1)) {
                            searchBarInputRef.current?.focus();
                            return;
                        }
                        if (currentFocusedElement === resultLinkArray[i]) {
                            // Focus on next ref.
                            resultLinkArray[i+1]?.focus()
                            return;
                        }
                    }
                }
                // Up arrow = go to previous (should be like shift-tab)
                if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    // If on search field, go to last element in list.
                    if (currentFocusedElement === searchBarInputRef.current) {
                        const lastElementIndex= resultLinkArray.length - 1;
                        resultLinkArray[lastElementIndex]?.focus();
                        return;
                    }
                    // If on first element, focus on search field input.
                    if (currentFocusedElement === resultLinkArray[0]) {
                        searchBarInputRef.current?.focus();
                        return;
                    }
                    // If focus is on element in list, move to previous. 
                    for (let i = 1; i < resultLinkArray.length; i++) {
                        if (currentFocusedElement === resultLinkArray[i]) {
                            // Focus on next ref.
                            resultLinkArray[i-1]?.focus();
                            return;
                        }
                    }
                }
            }
        }
        document.addEventListener('keydown', onKeyPress);
        return () => document.removeEventListener('keydown', onKeyPress);
    }, []);

    useEffect(() => {
        const handleClickOutsideOfSearch = (e: MouseEvent) => {
            const isElementSubChild = (allegedChild: Element, allegedParent: Element) => {
                const rootElement = document.documentElement;
                let currentElement: Element | null = allegedChild;
                while (currentElement !== null && currentElement !== rootElement) {
                    if (currentElement.className.includes('ElementSubChildTrue')) return true;
                    if (currentElement === allegedParent) {
                        return true;
                    }
                    currentElement = currentElement.parentElement;
                }
                return false;
            }
            // Only unfocus input if:
            // - click was outside of KCL_search-response.
            // - AND click was outside of input field-control.
            const { target } = e;
            const searchBarParent = searchBarParentRef.current;
            if (target instanceof Element && searchBarParent) {
                // If user clicks on element, which is not in header, then turn off focus.
                if (isElementSubChild(target, searchBarParent)) {
                    if (preventClickRef.current) {
                        preventClickRef.current = false;
                    } else {
                        setIsFocused(true);
                    }
                } else {
                    setIsFocused(false);
                }
            } else {
                setIsFocused(false);
            }
        }
        document.addEventListener('click', handleClickOutsideOfSearch);
        return () => document.removeEventListener('click', handleClickOutsideOfSearch);
    }, [])

    // Functions
    const displayResults = () => {
        if (results === null) return null;
        return (
            <div className='SearchBarResults'>
                {results.map((veryBasicCompanyInfo, index) => (
                    <a
                        key={index}
                        href={`/company/${veryBasicCompanyInfo.name}`}
                        ref={el => { resultLinkArrayRef.current[index] = el; }}
                        onClick={(e) => {
                            if (onSelectOverride) {
                                e.preventDefault();
                                preventClickRef.current = true;
                                e.currentTarget.blur();
                                onSelectOverride(veryBasicCompanyInfo);
                                setIsFocused(false);
                            }
                        }}
                    >
                        {isBookmarkable && (
                            <span
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <WatchlistIcon
                                    className='ElementSubChildTrue'
                                    companyName={veryBasicCompanyInfo.name}
                                    companyMnemonic={veryBasicCompanyInfo.mnemonic}
                                />
                            </span>
                        )}
                        {veryBasicCompanyInfo.name} ({veryBasicCompanyInfo.mnemonic})
                    </a>
                ))}
            </div>
        );
    }

    return (
        <div
            ref={searchBarParentRef}
            className={cx('SearchBar', {
                'IsBig': isBig,
                'ShowResults': showResults
            })}
        >
            <FontAwesomeIcon
                icon={faSearch}
                className='SearchIcon'
                onClick={() => searchBarInputRef.current?.focus()}
            />
            <Form.Control
                ref={searchBarInputRef}
                className={cx('SearchBarInput', { 'IsFocused': isFocused })}
                placeholder={placeholder || 'Search for a company'}
                value={searchBarValue}
                onChange={e => setSearchBarValue(e.target.value.toLowerCase())}
                onFocus={() => setIsFocused(true)}
            />
            {displayResults()}
        </div>
    );
}

function mapStateToProps({ companiesReducer }: RootState) {
    const { veryBasicCompanyInfo } = companiesReducer;
    return {
        veryBasicCompanyInfo
    };
}

const connector = connect(mapStateToProps, { requestVeryBasicCompanyInfo });
export default connector(SearchBar);

