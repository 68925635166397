import { ICompanyInfo, IDetailedCompanyInfo, IVeryBasicCompanyInfo, IIncomeStatement, ICompanyQuote } from '../api/CompaniesTypes';

/* FETCH ALL FORTUNE 500 COMPANIES */
export const FETCH_FORTUNE_500_COMPANIES_LOADING = 'FETCH_FORTUNE_500_COMPANIES_LOADING';
export const FETCH_FORTUNE_500_COMPANIES_SUCCESS = 'FETCH_FORTUNE_500_COMPANIES_SUCCESS';
export const FETCH_FORTUNE_500_COMPANIES_FAILURE = 'FETCH_FORTUNE_500_COMPANIES_FAILURE';
interface IFetchFortune500CompaniesLoadingAction {
    type: typeof FETCH_FORTUNE_500_COMPANIES_LOADING
}
interface IFetchFortune500CompaniesSuccessAction {
    type: typeof FETCH_FORTUNE_500_COMPANIES_SUCCESS,
    payload: Array<ICompanyInfo>
}
interface IFetchFortune500CompaniesFailureAction {
    type: typeof FETCH_FORTUNE_500_COMPANIES_FAILURE,
    payload: string
}

/* FETCH SINGLE COMPANY DETAILS */
export const FETCH_SINGLE_COMPANY_DETAILS_LOADING = 'FETCH_SINGLE_COMPANY_DETAILS_LOADING';
export const FETCH_SINGLE_COMPANY_DETAILS_SUCCESS = 'FETCH_SINGLE_COMPANY_DETAILS_SUCCESS';
export const FETCH_SINGLE_COMPANY_DETAILS_FAILURE = 'FETCH_SINGLE_COMPANY_DETAILS_FAILURE';
interface IFetchSingleCompanyLoadingAction {
    type: typeof FETCH_SINGLE_COMPANY_DETAILS_LOADING
    payload: {
        companyName: string
    }
}
interface IFetchSingleCompanySuccessAction {
    type: typeof FETCH_SINGLE_COMPANY_DETAILS_SUCCESS,
    payload: IDetailedCompanyInfo
}
interface IFetchSingleCompanyFailureAction {
    type: typeof FETCH_SINGLE_COMPANY_DETAILS_FAILURE,
    payload: {
        companyName: string,
        error: string
    }
}

/* FETCH COMPANY QUOTE */
export const FETCH_COMPANY_QUOTE_LOADING = 'FETCH_COMPANY_QUOTE_LOADING';
export const FETCH_COMPANY_QUOTE_SUCCESS = 'FETCH_COMPANY_QUOTE_SUCCESS';
export const FETCH_COMPANY_QUOTE_FAILURE = 'FETCH_COMPANY_QUOTE_FAILURE';
interface IFetchCompanyQuoteLoadingAction {
    type: typeof FETCH_COMPANY_QUOTE_LOADING
}
interface IFetchCompanyQuoteSuccessAction {
    type: typeof FETCH_COMPANY_QUOTE_SUCCESS,
    payload: ICompanyQuote[]
}
interface IFetchCompanyQuoteFailureAction {
    type: typeof FETCH_COMPANY_QUOTE_FAILURE,
    payload: string
}

/* FETCH INCOME STATEMENT */
export const FETCH_INCOME_STATEMENT_LOADING = 'FETCH_INCOME_STATEMENT_LOADING';
export const FETCH_INCOME_STATEMENT_SUCCESS = 'FETCH_INCOME_STATEMENT_SUCCESS';
export const FETCH_INCOME_STATEMENT_FAILURE = 'FETCH_INCOME_STATEMENT_FAILURE';
interface IFetchIncomeStatementLoadingAction {
    type: typeof FETCH_INCOME_STATEMENT_LOADING
}
interface IFetchIncomeStatementSuccessAction {
    type: typeof FETCH_INCOME_STATEMENT_SUCCESS,
    payload: IIncomeStatement[]
}
interface IFetchIncomeStatementFailureAction {
    type: typeof FETCH_INCOME_STATEMENT_FAILURE,
    payload: string
}

/* FETCH MULTIPLE COMPANIES DETAILS */
export const FETCH_MULTIPLE_COMPANY_DETAILS_LOADING = 'FETCH_MULTIPLE_COMPANY_DETAILS_LOADING';
export const FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS = 'FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS';
export const FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE = 'FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE';
interface IFetchMultipleCompanyDetailsLoadingAction {
    type: typeof FETCH_MULTIPLE_COMPANY_DETAILS_LOADING
    payload: {
        companyNames: string[]
    }
}
interface IFetchMultipleCompanyDetailsSuccessAction {
    type: typeof FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS,
    payload: IDetailedCompanyInfo[]
}
interface IFetchMultipleCompanyDetailsFailureAction {
    type: typeof FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE,
    payload: {
        companyNames: string[],
        error: string
    }
}

/* FETCH VERY BASIC COMPANY INFO */
export const FETCH_VERY_BASIC_COMPANY_INFO_LOADING = 'FETCH_VERY_BASIC_COMPANY_INFO_LOADING';
export const FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS = 'FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS';
export const FETCH_VERY_BASIC_COMPANY_INFO_FAILURE = 'FETCH_VERY_BASIC_COMPANY_INFO_FAILURE';
interface IFetchVeryBasicCopmanyInfoLoadingAction {
    type: typeof FETCH_VERY_BASIC_COMPANY_INFO_LOADING
}
interface IFetchVeryBasicCopmanyInfoSuccessAction {
    type: typeof FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS,
    payload: Array<IVeryBasicCompanyInfo>
}
interface IFetchVeryBasicCopmanyInfoFailureAction {
    type: typeof FETCH_VERY_BASIC_COMPANY_INFO_FAILURE,
    payload: string
}


/* EXPORT */
export type CompaniesActions =
// Fetch Fortune 500.
IFetchFortune500CompaniesSuccessAction
| IFetchFortune500CompaniesFailureAction
| IFetchFortune500CompaniesLoadingAction
// Fetch Single company
| IFetchSingleCompanySuccessAction
| IFetchSingleCompanyFailureAction
| IFetchSingleCompanyLoadingAction
// Fetch multiple companies details.
| IFetchMultipleCompanyDetailsLoadingAction
| IFetchMultipleCompanyDetailsSuccessAction
| IFetchMultipleCompanyDetailsFailureAction
// Fetch very basic company info
| IFetchVeryBasicCopmanyInfoLoadingAction
| IFetchVeryBasicCopmanyInfoSuccessAction
| IFetchVeryBasicCopmanyInfoFailureAction
// Fetch company quote
| IFetchCompanyQuoteLoadingAction
| IFetchCompanyQuoteSuccessAction
| IFetchCompanyQuoteFailureAction
// Fetch income statement
| IFetchIncomeStatementLoadingAction
| IFetchIncomeStatementSuccessAction
| IFetchIncomeStatementFailureAction;