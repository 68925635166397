// Package imports:
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './BottomRatedList.scss';
// Component imports:
import Table from 'react-bootstrap/Table';
import WatchlistIcon from '../../Common/WatchlistIcon';
// Type imports:
import { RootState } from '../../../redux-store';

const NUMBER_OF_ITEMS = 10;

type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps;

class BottomRatedList extends React.Component<Props> {
    displayBottomCompanies = () => {
        const { companiesList, error } = this.props;
        if (error !== null) {
            return (
                <div className='ErrorWrapper'>
                    <h3>Error retrieving data.</h3>
                </div>
            )
        }
        if (companiesList === 'loading' || companiesList === null) {
            return (
                <div className='LoadingWrapper'>
                    <h3>Loading...</h3>
                </div>
            )
        }
        const bottomRatedList = [...companiesList].sort((a,b) => {
            if (a.gemmaq_rating < b.gemmaq_rating) return -1;
            if (a.gemmaq_rating > b.gemmaq_rating) return 1;
            return 0;
        })
        const bottomX = bottomRatedList.slice(0, NUMBER_OF_ITEMS);
        const jsxRows = bottomX.map((companyInfo, index) => {
            return (
                <tr key={index}>
                    <td className='TableTextLeft'>
                        <WatchlistIcon
                            companyName={companyInfo.company}
                            companyMnemonic={companyInfo.mnemonic}
                        />
                        <a href={`/company/${companyInfo.company}`}>
                            {companyInfo.company}
                        </a>
                    </td>
                    <td className='TableTextRight'>{Math.floor(companyInfo.gemmaq_rating * 2) / 2}</td>
                </tr>
            );
        });
        return (
            <>
                <div className='TableHeaderWrapper'>
                    <p className='TableHeader FirstTableHeader'>
                        Company
                    </p>
                    <div className='TableHeaderJustifyRight'>
                        <p className='TableHeader LastTableHeader'>
                            Gemmaq Rating
                        </p>
                    </div>
                </div>
                <Table>
                    <tbody>
                        {jsxRows}
                    </tbody>
                </Table>
            </>
        )
    }

    render() {
        return (
            <div id='BottomRatedList'>
                {this.displayBottomCompanies()}
            </div>
        )
    }
}

function mapStateToProps({ companiesReducer }: RootState) {
    const { companiesList, error } = companiesReducer;
    return {
        companiesList,
        error
    }
}

const connector = connect(mapStateToProps);
export default connector(BottomRatedList);;