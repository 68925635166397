import jwt_decode from 'jwt-decode';
import { LoginActions, LOG_OUT, SET_LOGIN_INFO } from '../../types/redux/LoginTypes';

export interface ILoginState {
    accessToken: string | null,
    expiresAt_ms: number | null,
    idToken: string | null,
    email: string | null,
    username: string | null
}

// const getInitialState = () => ({
//     accessToken: '123',
//     expiresAt_ms: 1e15,
//     idToken: '123',
//     email: 'atli@kodi.is',
//     username: 'Atli Gislason'
// });
const getInitialState = () => ({
    accessToken: null,
    expiresAt_ms: null,
    idToken: null,
    email: null,
    username: null
});

const initialState: ILoginState = getInitialState();

try {
    const localStorageAccessToken = localStorage.getItem('GEMMAQ_AccessToken');
    const localStorageIdToken = localStorage.getItem('GEMMAQ_IdToken');
    const localStorageExpiresAt = localStorage.getItem('GEMMAQ_ExpiresAt');
    if (localStorageAccessToken !== null && localStorageIdToken !== null && localStorageExpiresAt !== null) {
        const expiresAtNum = parseInt(localStorageExpiresAt);
        const decodedIdToken = jwt_decode(localStorageIdToken);
        const email = (decodedIdToken as any)?.email;
        const username = (decodedIdToken as any)?.name;

        initialState.accessToken = localStorageAccessToken;
        initialState.idToken = localStorageIdToken;
        initialState.expiresAt_ms = expiresAtNum;
        initialState.email = email;
        initialState.username = username;
    }
} catch (e) {
    // Local storage can thow errors, due to privacy settings.
}

export default function(state: ILoginState = initialState, action: LoginActions): ILoginState {
    switch(action.type) {
        case SET_LOGIN_INFO:
        {
            const { accessToken, idToken, expiresIn } = action.payload;
            try {
                const decodedIdToken = jwt_decode(idToken);
                const expiresInNum = parseInt(expiresIn);
                const expiresAt_ms = Date.now() + (expiresInNum * 1000);
                const email = (decodedIdToken as any)?.email;
                const username = (decodedIdToken as any)?.name;
                try {
                    localStorage.setItem('GEMMAQ_AccessToken', accessToken);
                    localStorage.setItem('GEMMAQ_IdToken', idToken);
                    localStorage.setItem('GEMMAQ_ExpiresAt', expiresAt_ms.toString());
                    // Remove access and id token from browser query.
                    window.history.replaceState({}, document.title, '/');
                } catch (e) {
                    console.error(e)
                    // Local storage can thow errors, due to privacy settings.
                }
                const newState: ILoginState = {
                    ...state,
                    accessToken,
                    expiresAt_ms,
                    idToken,
                    email,
                    username
                }
                return newState;
            }
            // Something went wrong in decoding or parsing. Not logged in.
            catch (e) {
                console.error(e)
                return getInitialState();
            }
        }
        case LOG_OUT:
        {
            try {
                localStorage.removeItem('GEMMAQ_AccessToken');
                localStorage.removeItem('GEMMAQ_IdToken');
                localStorage.removeItem('GEMMAQ_ExpiresAt');
            } catch (e) {
                // Local storage can thow errors, due to privacy settings.
            }
            return getInitialState();
        }
        default:
            return state;
    }
}