// Package imports:
import React from 'react';
// Style imports:
import './AboutUs.scss';
// Image imports:
    // People:
import FreyjaImage from '../../assets/people/Freyja.jpg';
// Awards and Funding:
import NewYorkVCLogo from '../../assets/awards_and_funding/newyorkvc.jpg';
import ColumbiaVentureCommunityLogo from '../../assets/awards_and_funding/columbiaventurecommunity.png';
import TechnologyDevelopmentFundLogo from '../../assets/awards_and_funding/technologydevelopmentfund.jpg';
import IslandsbankiLogo from '../../assets/awards_and_funding/islandsbanki.png';
import JafnrettisSjodurLogo from '../../assets/awards_and_funding/jafnrettissjodur.png';
import CrowberryLogo from '../../assets/awards_and_funding/crowberry.png';
import NordicWomenLogo from '../../assets/awards_and_funding/nordicwomen.png';
// Component imports:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AWARDS_AND_FUNDING_LOGOS = [{
    image: NewYorkVCLogo,
    altText: 'New York VC Logo'
}, {
    image: ColumbiaVentureCommunityLogo,
    altText: 'Columbia Venture Community Logo'
}, {
    image: TechnologyDevelopmentFundLogo,
    altText: 'Technology Development Fund Logo'
}, {
    image: IslandsbankiLogo,
    altText: 'Islandsbanki Logo'
}, {
    image: JafnrettisSjodurLogo,
    altText: 'Gender Equality Fund Logo'
}, {
    image: CrowberryLogo,
    altText: 'Crowberry Capital Logo'
}, {
    image: NordicWomenLogo,
    altText: 'Nordic Women in Technology Logo'
}]

const AboutUs = () => {
    return (
        <Container id='AboutUs'>
            <Row>
                <Col>
                    <div className='PageHorizontalLine'></div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1 className='PageHeader'>About GemmaQ</h1>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    <div className='PersonCard'>
                        <h3>Freyja Thorarinsdottir</h3>
                        <img
                            className='PersonCardImage'
                            src={FreyjaImage}
                            alt='Freyja Thorarinsdottir'
                        />
                        <p>
                            Before launching GemmaQ in 2019, Freyja built a career in financial services; she was a director and team leader at the Central Bank of Iceland (The Federal Reserve) and participated in a financial advisor program with Merrill Lynch. Prior to that, Freyja was a capital markets and restructuring lawyer - passionate about improving the gender balance in senior leadership in her home country, Iceland. Now on a professional mission to help global markets invest directly in gender diversity by leveraging technology.  Freyja received a Master in Economic Policy Management from Columbia University, Law degrees from Reykjavik University, and a BA in Political Science from University of Iceland - spending one year at Copenhagen University in Denmark.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>                
                    <h2>Awards and Funding</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                         GemmaQ was among Top 10 ESG startups and early stage teams to look out for in 2022 - a list compiled by New York VC Network. GemmaQ also landed on the Top 50 NY VC startup list in 2021 and was selected on Columbia Venture Community Top 20 startup list in the Fall 2020.  Freyja was the winner of the Nordic Women in Tech Awards, Rising Star of the Year, Fall 2021.
                    </p>
		    <p>
                        Crowberry Capital led GemmaQ’s first funding round in May 2022. Previously, GemmaQ had received a highly competitive startup grant from the Icelandic Technology Development Fund during 2019-2021 and was awarded a project grant from the Entrepreneur Fund of Islandsbanki in 2019. Prior to founding GemmaQ in 2019, Freyja was awarded project grants from the Gender Equality Fund at the Ministry of Welfare in Iceland in 2018 and 2017.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='AwardAndFundingLogoWrapper'>
                        {
                            AWARDS_AND_FUNDING_LOGOS.map(({ image, altText }, index) => (
                                <img
                                    key={index}
                                    className='AwardAndFundingLogo'
                                    src={image}
                                    alt={altText}
                                />
                            ))
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs;
