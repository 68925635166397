// Package imports:
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './GemmaqSectorAverage.scss';
// Component imports:
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/pro-regular-svg-icons';
// Type imports:
import { RootState } from '../../../redux-store';

type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps;

const GemmaqSectorAverage: React.FC<Props> = ({
    companySectorSummaries,
    error
}) => {
    const displaySectorAverages = () => {
        if (error !== null) {
            return (
                <div className='ErrorWrapper'>
                    <h3>Error retrieving data.</h3>
                </div>
            )
        }
        if (companySectorSummaries === null) {
            return (
                <div className='LoadingWrapper'>
                    <h3>Loading...</h3>
                </div>
            )
        }

        const jsxRows = companySectorSummaries.map(({ title, rating, changeRating }, index) => {
            const { ratingChangeClassname, arrowElement } = (changeRating > 0)
                ? {
                    ratingChangeClassname: 'Positive',
                    arrowElement: <FontAwesomeIcon icon={faLongArrowAltUp} className='Arrow' />
                }
                : (changeRating < 0)
                ? {
                    ratingChangeClassname: 'Negative',
                    arrowElement: <FontAwesomeIcon icon={faLongArrowAltDown} className='Arrow' />
                }
                : {
                    ratingChangeClassname: 'Neutral',
                    arrowElement: undefined
                };
            return (
                <tr key={index}>
                    <td className='TableTextLeft'>
                        {title}
                    </td>
                    <td className='TableTextRight RatingTd'>
                        {rating}
                    </td>
                    <td className={`TableTextRight RatingChangeTd ${ratingChangeClassname}`}>
                        {arrowElement}
                        {changeRating}
                    </td>
                </tr>
            );
        });
        return (
            <>
                <div className='TableHeaderWrapper'>
                    <p className='TableHeader FirstTableHeader'>
                        Company
                    </p>
                    <div className='TableHeaderJustifyRight'>
                        <p className='TableHeader LastTableHeader'>
                            Gemmaq Rating
                        </p>
                    </div>
                </div>
                <Table>
                    <tbody>
                        {jsxRows}
                    </tbody>
                </Table>
            </>
        )
    }

    return (
        <div id='GemmaqSectorAverage'>
            {displaySectorAverages()}
        </div>
    );
}

function mapStateToProps({ companiesReducer }: RootState) {
    const { companySectorSummaries, error } = companiesReducer;
    return {
        companySectorSummaries,
        error
    }
}

const connector = connect(mapStateToProps);
export default connector(GemmaqSectorAverage);