// Package imports:
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
// Style imports:
import './WatchlistIcon.scss';
// Component imports:
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons';
// Service imports:
// Action imports:
import { updateWatchlist } from '../../redux-store/actions/watchlistActions';
// Type imports:
import { RootState } from '../../redux-store';
import { IWatchlistItem } from '../../types/api/WatchlistTypes';

interface IOwnProps {
    className?: string,
    companyName: string,
    companyMnemonic: string
    asButton?: boolean
};
type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps & IOwnProps;


const WatchlistIcon: React.FC<Props> = ({
    className,
    companyName,
    companyMnemonic,
    watchlist,
    updateWatchlistError,
    idToken,
    updateWatchlist,
    asButton
}) => {
    // Initialize states.
    const [ shouldDisplayLoading, setShouldDisplayLoading ] = useState(false);
    
    useEffect(() => {
        setShouldDisplayLoading(false);
    }, [watchlist, updateWatchlistError]);

    // Declare click functions.
    const addCompanyToWatchlist = (
        watchlist: IWatchlistItem[],
        idToken: string,
        companyName: string,
        companyMnemonic: string
    ) => {
        const newList = watchlist.concat([{
            name: companyName,
            mnemonic: companyMnemonic
        }]);
        setShouldDisplayLoading(true);
        updateWatchlist(newList, idToken);
    }

    const removeCompanyFromWatchlist = (
        watchlist: IWatchlistItem[],
        idToken: string,
        companyName: string,
        companyMnemonic: string
    ) => {
        const newList = watchlist.filter(watchlistItem => (
            watchlistItem.name !== companyName
            && watchlistItem.mnemonic !== companyMnemonic
        ));
        setShouldDisplayLoading(true);
        updateWatchlist(newList, idToken);
    }

    if (idToken === null || watchlist === null || watchlist instanceof Error) return null;

    // Check props.
    if (watchlist === 'loading' || shouldDisplayLoading) {
        if (asButton) {
            return (
                <Button
                    disabled
                    className={cx('WatchlistIconButton', className)}
                    variant='outline-warning'
                >
                    <FontAwesomeIcon
                        title='Loading...'
                        icon={faSpinnerThird}
                        className='WatchlistIcon FaSpinner'
                    />
                    Loading...
                </Button>
            );
        } else {
            return (
                <FontAwesomeIcon
                    title='Loading...'
                    icon={faSpinnerThird}
                    className={cx('WatchlistIcon FaSpinner', className)}
                />
            );
        }
    }

    const isCompanyInWatchlist = watchlist.find(({ name, mnemonic }) => (
        name === companyName && mnemonic === companyMnemonic
    )) !== undefined;

    if (asButton) {
        if (isCompanyInWatchlist) {
            return (
                <Button
                    className={cx('WatchlistIconButton', className)}
                    variant='outline-warning'
                    onClick={() => removeCompanyFromWatchlist(watchlist, idToken, companyName, companyMnemonic)}
                >
                    <FontAwesomeIcon icon={faStarSolid} className='WatchlistIcon FaFullStar' />
                    Following Company
                </Button>
            );
        } else {
            return (
                <Button
                    className={cx('WatchlistIconButton', className)}
                    variant='outline-warning'
                    onClick={() => addCompanyToWatchlist(watchlist, idToken, companyName, companyMnemonic)}
                >
                    <FontAwesomeIcon icon={faStarRegular} className='WatchlistIcon FaEmptyStar' />
                    Follow Company
                </Button>
            );
        }
    } else {
        if (isCompanyInWatchlist) {
            return (
                <OverlayTrigger
                    placement='top'
                    delay={{ show: 200, hide: 200 }}
                    overlay={
                        <Tooltip id={`WatchlistIconTooltip${companyMnemonic}`}>
                            You are following this company on your Watchlist!
                            Click to Unfollow.
                        </Tooltip>
                    }
                >
                    <FontAwesomeIcon
                        icon={faStarSolid}
                        className={cx('WatchlistIcon FaFullStar', className)}
                        onClick={() => removeCompanyFromWatchlist(watchlist, idToken, companyName, companyMnemonic)}
                    />
                </OverlayTrigger>
            );
        } else {
            return (
                <OverlayTrigger
                    placement='top'
                    delay={{ show: 0, hide: 200 }}
                    overlay={
                        <Tooltip id={`WatchlistIconTooltip${companyMnemonic}`}>
                            Click to follow this company on your Watchlist!
                        </Tooltip>
                    }
                >
                    <FontAwesomeIcon
                        icon={faStarRegular}
                        className={cx('WatchlistIcon FaEmptyStar', className)}
                        onClick={() => addCompanyToWatchlist(watchlist, idToken, companyName, companyMnemonic)}
                    />
                </OverlayTrigger>
            );
        }
    }


}

function mapStateToProps({ watchlistReducer, loginReducer }: RootState) {
    const { watchlist, updateWatchlistError } = watchlistReducer;
    const { idToken } = loginReducer;
    return {
        watchlist,
        updateWatchlistError,
        idToken
    };
}

const connector = connect(mapStateToProps, { updateWatchlist });
export default connector(WatchlistIcon);