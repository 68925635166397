// Package imports:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
// Style imports:
import './Footer.scss';

const Footer: React.FC = () => {
    const socials = [{
        icon: faTwitter,
        url: `https://twitter.com/gemmaqmeasure`
    }, {
        icon: faInstagram,
        url: `https://www.instagram.com/gemmaqmeasure/`
    }, {
        icon: faFacebookF,
        url: `https://www.facebook.com/GEMMAQmeasure`
    }, {
        icon: faLinkedinIn,
        url: `https://www.linkedin.com/company/gemmaq/`
    }]

    return (
        <div id='Footer'>
            <div className='TextContainer'>
                {socials.map(({ icon, url }, index) => (
                    <a
                        key={index}
                        href={url}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FontAwesomeIcon icon={icon} />
                    </a>
                ))}
                <div className='AlignmentSpace' />
                <a href='https://gemmaq.org/genderequality'>Get in touch</a>
            </div>
        </div>
    )
}

export default Footer;