

export function isUserLoggedIn(accessToken: string | null, idToken: string | null, tokenExpiresAt: number | null) {
    if (accessToken === undefined
    || tokenExpiresAt === undefined
    || idToken === undefined) {
        throw new Error('isUserLoggedIn does not have all parameters');
    }
    if (tokenExpiresAt === null) return false;
    const currentTime = Date.now();
    const isLoggedIn = (accessToken !== '')
        && (idToken !== '')
        && (currentTime < tokenExpiresAt)
    return isLoggedIn
}