// Package imports:
import React, { Fragment, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
// Style imports:
import './CompanyProfile.scss';
// Component imports:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GemmaqChart from '../Common/GemmaqChart';
import WatchlistIcon from '../Common/WatchlistIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/pro-solid-svg-icons';
// Service imports:
import { sexToGender, formatNumberTo1Decimal, roundToHalfs, formatNumber, convertToPercentage, displayQuantityShorthand } from '../../services/utils';
// Action imports:
import { requestSingleCompanyInfo, requestCompanyQuote, requestIncomeStatement } from '../../redux-store/actions/companiesActions';
// Type imports:
import { RootState } from '../../redux-store';
import { ICompanyInfo, IDetailedCompanyInfo, IPerson, ICompanyQuote, IIncomeStatement } from '../../types/api/CompaniesTypes';

interface IOwnProps {
    companyName: string
}
type ReduxProps = ConnectedProps<typeof connector>;
type Props = IOwnProps & ReduxProps;

const CompanyProfile: React.FC<Props> = ({
    companyName,
    companyDetailsMap,
    companiesList,
    companyQuote,
    incomeStatement,
    requestSingleCompanyInfo,
    requestCompanyQuote,
    requestIncomeStatement
}) => {
    const [mnemonic, setMnemonic] = React.useState<string | null>(null);

    useEffect(() => {
        requestSingleCompanyInfo(companyName);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (mnemonic === null) {
            const companyDetails = companyDetailsMap[companyName];
            if (companyDetails === 'loading' || companyDetails instanceof Error) return;
            setMnemonic(companyDetails?.mnemonic ?? null);
        }
    }, [companyDetailsMap, companyName, mnemonic]);

    useEffect(() => {
        if (mnemonic === null) return;
        requestCompanyQuote(mnemonic);
        requestIncomeStatement(mnemonic);
    }, [mnemonic, requestCompanyQuote, requestIncomeStatement]);

    const displayGemmaqChart = (singleCompany: IDetailedCompanyInfo) => {
        return (
            <div className='ChartWrapper'>
                <GemmaqChart
                    rating={singleCompany.gemmaq_rating}
                    boardOfDirectors={singleCompany.percentage_female_board_members}
                    topExecutives={singleCompany.percentage_female_executives}
                    chairman={sexToGender(singleCompany.chairman[0].gender)}
                    ceo={sexToGender(singleCompany.ceo[0].gender)}
                />
            </div>
        );
    }

    const displayCompanyInfo = (singleCompany: IDetailedCompanyInfo) => {
        return (
            <div className='CompanyDataWrapper'>
                <div className='CompanyDataHeader'>
                    <h2>Company Information</h2>
                </div>
                <div className='CompanyDataTable'>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Name:
                                </td>
                                <td>
                                    {singleCompany.company}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Founding Year:
                                </td>
                                <td>
                                    {singleCompany.founding_year}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    State:
                                </td>
                                <td>
                                    {singleCompany.state}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Sector:
                                </td>
                                <td>
                                    {singleCompany.sector}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Exchange:
                                </td>
                                <td>
                                    {singleCompany.exchange}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Description:
                                </td>
                                <td
                                    // Use DangerouslySetInnerHTML because description can include html characters like &amp;
                                    dangerouslySetInnerHTML={{ __html: singleCompany.description || ('<i>No description</i>')}}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const displayCeoChairCards = (singleCompany: IDetailedCompanyInfo) => {
        const ceoCards = singleCompany.ceo.map((person, index) => getCeoChairCard(person, 'CEO', index, (singleCompany.chairman.length === 0)));
        const chairCards = singleCompany.chairman.map((person, index) => getCeoChairCard(person, 'Chair', index, (index === singleCompany.chairman.length - 1)));
        
        return (
            <div className='GenderCardWrapper'>
                {ceoCards}
                {chairCards}
            </div>
        )
    }

    const getCeoChairCard = (person: IPerson, title: 'CEO' | 'Chair', index: number, isLast?: boolean) => {
        const getName = (person: IPerson) => {
            const { first_name, middle_names, last_name } = person;
            return (first_name + ' ' + middle_names + ' ' + last_name).toLowerCase();
        }
        if (person.gender === 'not set') {
            return (
                <div className='GenderCard NotSet'>
                    <div className='GenderCardTitle'>
                        {title}
                    </div>
                    <div className='GenderCardName'>
                        Not Set
                    </div>
                </div>
            );
        }
        return (
            <Fragment key={index}>
                <div className={`GenderCard ${person.gender}`}>
                    <div className='GenderCardTitle'>
                        {title}
                    </div>
                    <div className='GenderCardName'>
                        {getName(person)}
                    </div>
                    <div className='GenderCardIcon'>
                        {
                            (person.gender === 'Male')
                            ? <FontAwesomeIcon icon={faMale} size='2x' className='FaMale' />
                            : <FontAwesomeIcon icon={faFemale} size='2x' className='FaFemale' />
                        }
                    </div>
                </div>
                {
                    (!isLast)
                    ? (<div className='GenderCardDivider'></div>)
                    : null
                }
            </Fragment>
        );
    }

    const getUsaRanking = (allCompanies: Array<ICompanyInfo> | null, thisCompaniesRating: number) => {
        if (allCompanies === null) return null;
        allCompanies.sort((a,b) => {
            // Rating is sorted in ascending order.
            if (a.gemmaq_rating < b.gemmaq_rating) return -1;
            if (a.gemmaq_rating > b.gemmaq_rating) return 1;
            return 0;
        });

        let index = 0;
        for (; index < allCompanies.length; index++) {
            if (thisCompaniesRating < allCompanies[index].gemmaq_rating) break;
        }

        return (100 * index / allCompanies.length);
    }

    const displayGemmaqRatings = (singleCompany: IDetailedCompanyInfo) => {
        // Calculate US Ranking from 500 companies.
        const allCompanies = (companiesList === 'loading' || companiesList === null)
            ? null
            : [...companiesList];

        const usaRanking = getUsaRanking(allCompanies, singleCompany.gemmaq_rating);
        
        // Find Change.
        const change = allCompanies?.find(company => company.company === singleCompany.company)?.rating_change ?? null;

        const ratings = [{
            title: 'Total',
            rating: roundToHalfs(singleCompany.gemmaq_rating)
        }, {
            title: 'Board',
            rating: roundToHalfs(singleCompany.board_sub_rating)
        }, {
            title: 'Executives',
            rating: roundToHalfs(singleCompany.executive_sub_rating)
        }, {
            title: 'Change',
            rating: roundToHalfs(change)
        }, {
            title: 'US Ranking',
            rating: usaRanking
        }];
        return (
            <div className='GemmaqRatingsWrapper'>
                <div className='GemmaqRatingsHeader'>
                    <h2>GEMMAQ Ratings (0-10)</h2>
                    <div>
                        <a href='/about-measures'>
                            <FontAwesomeIcon icon={faChevronCircleRight} className='FaChevronCircleRight' />
                            Methodology
                        </a>
                    </div>
                </div>
                <div className='GemmaqRatings'>
                    {
                        ratings.map(({ rating, title }, index) => (
                            <div
                                className='GemmaqRatingsCard'
                                key={index}
                            >
                                <div className='Rating'>
                                    {
                                        // Case: US Ranking.
                                        (title === 'US Ranking')
                                        ? ((rating === null) ? '-' : ((Math.floor(rating) || 1) + '%'))
                                        // Case: Default
                                        : formatNumberTo1Decimal(rating, (title === 'Change'))
                                    }
                                </div>
                                <div className='Title'>
                                    {title}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    const displayFinancialData = (quote: ICompanyQuote, incomeStatement: IIncomeStatement) => {
        const data = [[{
            title: 'Annual Revenue',
            value: displayQuantityShorthand(incomeStatement.revenue, 0, '$')
        }, {
            title: 'Gross Profit Ratio',
            value: convertToPercentage(incomeStatement.grossProfitRatio, true, 2)
        }, {
            title: 'Today\'s Change',
            value: formatNumber(quote.changesPercentage, '-', 2, undefined, '%'),
            className: quote.changesPercentage > 0 ? 'pos' : quote.changesPercentage < 0 ? 'neg' : ''
        }], [{
            title: 'Market Capitalization',
            value: displayQuantityShorthand(quote.marketCap, 0, '$')
        }, {
            title: 'Price-to-Earnings (P/E) Ratio',
            value: formatNumber(quote.pe, '-', 2)
        }, {
            title: 'Current Stock Price',
            value: formatNumber(quote.price, '-', 2, '$')
        }]];
        return (
            <div className='FinancialDataWrapper'>
                <div className='FinancialDataHeader'>
                    <h2>Financial Data</h2>
                </div>
                <div className='FinancialData'>
                    {
                        data.map((row, index) => (
                            <div className='FinancialDataRow' key={index}>
                                {
                                    row.map(({ value, title, className}, index) => (
                                        <div
                                            className={cx('FinancialDataCard', className)}
                                            key={index}
                                        >
                                            <div className='Value'>
                                                {className === 'pos'
                                                    ? <FontAwesomeIcon icon={faLongArrowAltUp} className='FaArrow' />
                                                    : className === 'neg'
                                                    ? <FontAwesomeIcon icon={faLongArrowAltDown} className='FaArrow' />
                                                    : null
                                                }
                                                {value}
                                            </div>
                                            <div className='Title'>
                                                {title}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    const singleCompany = companyDetailsMap[companyName];
    const quote = companyQuote !== null && !(companyQuote instanceof Error) && companyQuote !== 'loading'
        ? companyQuote[0]
        : companyQuote;
    const incomeStatementData = incomeStatement !== null && !(incomeStatement instanceof Error) && incomeStatement !== 'loading'
        ? incomeStatement[0]
        : incomeStatement;
    return (
        <Container id='CompanyProfile'>
            <Row>
                <Col xl={6} md={6} xs={12}>
                    <h1 className='PageHeader'>
                        {companyName}
                    </h1>
                </Col>
                <Col xl={6} md={6} xs={12} className='WatchlistButtonCol'>
                    {
                        (singleCompany === undefined || singleCompany === 'loading' || singleCompany instanceof Error)
                        ? null
                        : (
                            <WatchlistIcon
                                companyName={singleCompany.company}
                                companyMnemonic={singleCompany.mnemonic}
                                asButton={true}
                            />
                        )
                    }
                </Col>
            </Row>
            {
                (singleCompany === undefined || singleCompany === 'loading')
                ? (
                    <Row>
                        <Col>
                            <div className='LoadingWrapper'>
                                <h3>Loading...</h3>
                            </div>
                        </Col>
                    </Row>
                )
                : (singleCompany instanceof Error)
                ? (
                    <Row>
                        <Col>
                            <div className='ErrorWrapper'>
                                <h3>Error: {singleCompany.message}</h3>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xl={8} md={12} xs={12}>
                            <Row>
                                <Col xl={6} md={6} xs={12}>
                                    {displayGemmaqChart(singleCompany)}
                                </Col>
                                <Col xl={6} md={6} xs={12}>
                                    {displayCeoChairCards(singleCompany)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} md={12} xs={12}>
                                    {displayGemmaqRatings(singleCompany)}
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={4} md={12} xs={12}>
                            <Row>
                                {
                                    (quote === null || quote === 'loading' || incomeStatementData === null || incomeStatementData === 'loading')
                                    ? (
                                        <Col>
                                            <div className='LoadingWrapper'>
                                                <h3>Loading...</h3>
                                            </div>
                                        </Col>
                                    ) : (quote instanceof Error)
                                    ? (
                                        <Col>
                                            <div className='ErrorWrapper'>
                                                <h3>Error: {quote.message}</h3>
                                            </div>
                                        </Col>
                                    ) : (incomeStatementData instanceof Error)
                                    ? (
                                        <Col>
                                            <div className='ErrorWrapper'>
                                                <h3>Error: {incomeStatementData.message}</h3>
                                            </div>
                                        </Col>
                                    ) : (
                                        <Col xl={12} md={6} xs={12}>
                                            {displayFinancialData(quote, incomeStatementData)}
                                        </Col>
                                    )
                                }
                                <Col xl={12} md={6} xs={12}>
                                    {displayCompanyInfo(singleCompany)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }
        </Container>
    );
}

function mapStateToProps({ companiesReducer }: RootState) {
    const { companyDetailsMap, companiesList, companyQuote, incomeStatement } = companiesReducer;
    return {
        companyDetailsMap,
        companiesList,
        companyQuote,
        incomeStatement
    }
}

const connector = connect(mapStateToProps, { requestSingleCompanyInfo, requestCompanyQuote, requestIncomeStatement });
export default connector(CompanyProfile);