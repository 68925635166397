// Package imports:
import React from 'react';
// Style imports:
import './PanelWrapper.scss';

interface IOwnProps {
    title: string,
    id?:string
}

type Props = IOwnProps

export default function PanelWrapper({ children, title, id }: React.PropsWithChildren<Props>) {
    return (
        <div
            id={id}
            className='PanelWrapper'
        >
            <h2 className='Header'>{title}</h2>
            <div className='SectionHeaderHorizontalLine'></div>
            {children}
        </div>
    )
}