import { INewsCardResponse } from '../api/NewsFeedTypes';

export const ADD_NEWS_CARDS_SUCCESS = 'ADD_NEWS_CARDS_SUCCESS';
export const ADD_NEWS_CARDS_FAILURE = 'ADD_NEWS_CARDS_FAILURE';

interface IAddNewsCardsActionSuccess {
    type: typeof ADD_NEWS_CARDS_SUCCESS,
    payload: INewsCardResponse
}

interface IAddNewsCardsActionFailure {
    type: typeof ADD_NEWS_CARDS_FAILURE,
    payload: string
}

export type NewsFeedActions =
IAddNewsCardsActionSuccess
| IAddNewsCardsActionFailure;