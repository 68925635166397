// Package imports:
import React from 'react';
// Style imports:
import './CompositePanel.scss';
// Component imports:
import GemmaqChart from '../../Common/GemmaqChart';
import Table from 'react-bootstrap/Table';
// Type imports:
import { RootState } from '../../../redux-store';
import { connect, ConnectedProps } from 'react-redux';

type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps;

class CompositePanel extends React.Component<Props> {
    private chartElement: HTMLDivElement | null;
    constructor(props: Props) {
        super(props);
        this.chartElement = null;
    }

    renderGemmaqChart = () => {
        const { compositeInfo } = this.props;
        if (compositeInfo === null) return null;
        const {
            averageRating,
            averagePercentCeo,
            averagePercentChairman,
            averageTopExecutives,
            averageBoardOfDirectors
        } = compositeInfo;
        return (
            <GemmaqChart
                rating={Math.round(averageRating * 2) / 2}
                ceo={averagePercentCeo}
                chairman={averagePercentChairman}
                topExecutives={averageTopExecutives}
                boardOfDirectors={averageBoardOfDirectors}
                precise={true}
            />
        );
    }

    setChartElement = (instance: HTMLDivElement) => {
        this.chartElement = instance;
        this.forceUpdate();
    }

    displayChartAndTable = () => {
        const { error, compositeInfo } = this.props;
        if (error !== null) {
            return (
                <div className='ErrorWrapper'>
                    <h3>Error retrieving data.</h3>
                </div>
            );
        } else if (compositeInfo === null) {
            return (
                <div className='LoadingWrapper'>
                    <h3>Loading...</h3>
                </div>
            );
        }
        const { averageRating, averageChange } = compositeInfo;
        const { averageChangeString, averageChangeClassName } = (averageChange === null)
            ? {
                averageChangeString: undefined,
                averageChangeClassName: undefined
            }
            : (averageChange > 0)
            ? {
                averageChangeString: '+' + averageChange,
                averageChangeClassName: 'Positive'
            }
            : (averageChange < 0)
            ? {
                averageChangeString: averageChange,
                averageChangeClassName: 'Negative'
            }
            : {
                averageChangeString: averageChange,
                averageChangeClassName: undefined
            }
        return (
            <>
                <p className='TableHeader'>Fortune 500 Average</p>
                <div
                    ref={this.setChartElement}
                    className='ChartWrapper'
                >
                    {this.renderGemmaqChart()}
                </div>
                <div className='InfoWrapper'>
                    <h3>GEMMAQ Average</h3>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Gender Composite:</td>
                                <td>{averageRating}</td>
                            </tr>
                            <tr>
                                <td>Change since last update:</td>
                                <td className={averageChangeClassName}>
                                    {averageChangeString}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    render() {
        return (
            <div id='CompositePanel'>
                {this.displayChartAndTable()}
            </div>
        );
    }
}

function mapStateToProps({ companiesReducer }: RootState) {
    const { compositeInfo, error } = companiesReducer;
    return {
        compositeInfo,
        error
    }
}

const connector = connect(mapStateToProps);
export default connector(CompositePanel);