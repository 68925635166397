import { LoginActions, LOG_OUT, SET_LOGIN_INFO } from '../../types/redux/LoginTypes';

export const setLoginInfo = (accessToken: string, expiresIn: string, idToken: string): LoginActions => ({
    type: SET_LOGIN_INFO,
    payload: {
        accessToken,
        expiresIn,
        idToken
    }
});

export const logOut = () => ({
    type: LOG_OUT
})