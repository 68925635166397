import { Gender, ICompanyInfo, IDetailedCompanyInfo } from '../types/api/CompaniesTypes';
import { ICompositeInfo } from '../types/Types';

export const sexToGender = (str: Gender): 'Woman' | 'Man' | 'Not Set' => {
    if (str === 'Female') return 'Woman';
    if (str === 'Male') return 'Man';
    return 'Not Set';
}

export const formatNumberTo1Decimal = (num: number | null, showChange?: boolean) => {
    if (num === null) return '-';
    const isPositive = (num >= 0);
    const baseNumber = Math.floor(num);
    const decimalNumber = Math.floor((num - baseNumber) * 10);
    if (showChange && isPositive) {
        return '+' + baseNumber.toString() + '.' + decimalNumber.toString();
    } else {
        return baseNumber.toString() + '.' + decimalNumber.toString();
    }
}

export const getRatingFromPercent = (percent: number) => {
    // Find rough rating.
    const roughRating = percent / 5;
    // Round to nearest .5
    const roundedRating = Math.floor(roughRating * 2) / 2;
    // Cap to 10.
    const rating = Math.min(roundedRating, 10);
    return rating;
}

function roundToHalfs(num: number): number;
function roundToHalfs(num: null | undefined): null;
function roundToHalfs(num: number | null | undefined): number | null;
function roundToHalfs(num: number | null | undefined) {
    if (num === null || num === undefined) return null;
    return Math.round(num * 2) / 2;
}
export { roundToHalfs };

// Composite Info Calculator
export const getCompositeInfoFromCompanyList = (companies: IDetailedCompanyInfo[] | ICompanyInfo[]) => {
    const numberOfCompanies = companies.length;
    if (numberOfCompanies === 0) {
        return null;
    }
    let numberOfFemaleCeos = 0;
    let numberOfFemaleChairmen = 0;
    let totalPercentsOfTopExecutives = 0;
    let totalPercentsOfBoardOfDirectors = 0;
    let totalRating = 0;
    let totalChange = 0;
    for (const company of companies) {
        if (typeof company.ceo === 'string') {
            if (company.ceo === 'Female') numberOfFemaleCeos++;
        } else {
            for (let ceo of company.ceo) {
                if (ceo.gender === 'Female') numberOfFemaleCeos++;
            }
        }

        if (typeof (company as ICompanyInfo).chair === 'string') {
            if ((company as ICompanyInfo).chair === 'Female') numberOfFemaleChairmen++;
        } else {
            for (let chair of (company as IDetailedCompanyInfo).chairman) {
                if (chair.gender === 'Female') numberOfFemaleChairmen++;
            }
        }
        totalPercentsOfTopExecutives += company.percentage_female_executives;
        totalPercentsOfBoardOfDirectors += company.percentage_female_board_members;
        totalRating += company.gemmaq_rating;
        totalChange += (company as ICompanyInfo )?.rating_change ?? 0;
    }
    const compositeInfo: ICompositeInfo = {
        averageRating: Math.round(1000 * totalRating / numberOfCompanies) / 1000,
        averageChange: Math.round(1000 * totalChange / numberOfCompanies) / 1000,
        averagePercentCeo: (100 * numberOfFemaleCeos / numberOfCompanies),
        averagePercentChairman: (100 * numberOfFemaleChairmen / numberOfCompanies),
        averageTopExecutives: (totalPercentsOfTopExecutives / numberOfCompanies),
        averageBoardOfDirectors: (totalPercentsOfBoardOfDirectors / numberOfCompanies),
    }
    return compositeInfo;
}

export const formatNumber = (
    num: number | null | undefined,
    fallBackSymbol: string | null = '-',
    fractionDigits: number = 2,
    prefix = '',
    suffix = ''
) => {
    if (num === null || num === undefined || isNaN(num)) {
        return fallBackSymbol;
    }

    const formattedNumber = new Intl.NumberFormat('us-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(num);
    return prefix + formattedNumber + suffix;
}

export function convertToPercentage(num: number | null, displayPercentageSign: boolean, fractionDigits: number = 2) {
    if (num === null) {
        return '-';
    }
    num = num * 100;

    if (displayPercentageSign) {
        return (formatNumber(num, null, fractionDigits) + '%');
    } else {
        return (formatNumber(num, null, fractionDigits));
    }

}

export function displayQuantityShorthand(quantity: number | null, fractionDigits?: number, prefix: string = ''): string | null {
    if (quantity === null) {
        return '-';
    }
    if (quantity < 0) {
        return `-${displayQuantityShorthand(-quantity)}`
    }
    //check if its 1M or more
    if (quantity >= 1000000) {
        return formatNumber((quantity / 1000000), null, fractionDigits, prefix, 'M');
    } else if (quantity >= 1000) {
        return formatNumber((quantity / 1000), null, fractionDigits, prefix, 'K');
    }
    return prefix + '0';
}