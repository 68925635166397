// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
// Style imports:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

interface INavItem {
    link: string,
    label: string
}

interface IProps {
    label: string,
    navItems: Array<INavItem>
}

const NavigationDropdown: React.FC<IProps> = ({
    label,
    navItems
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return <div className={cx('NavBarDropdownItem', {'is-open': isOpen})}>
        <button type="button" className='NavBarLink' onClick={() => setIsOpen(!isOpen)}>
            {label}
            <span className='icon'>
                <FontAwesomeIcon icon={faChevronDown} />
            </span>
        </button>
        <div className='NavBarDropdown'>
            <div className='colorBar'></div>
            <ul>
                {navItems.map((item, index) => (
                    <li key={index}>
                        <a
                            href={item.link}
                            className='NavBarDropdownLink'
                        >
                            {item.label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
}

export default NavigationDropdown;