import { getCompositeInfoFromCompanyList } from '../../services/utils';
import {
    ICompanyInfo,
    IDetailedCompanyInfo,
    ISummaryAverage,
    IVeryBasicCompanyInfo,
    ICompanyQuote,
    IIncomeStatement
} from '../../types/api/CompaniesTypes';
import {
    CompaniesActions,
    FETCH_FORTUNE_500_COMPANIES_FAILURE,
    FETCH_FORTUNE_500_COMPANIES_LOADING,
    FETCH_FORTUNE_500_COMPANIES_SUCCESS,
    FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE,
    FETCH_MULTIPLE_COMPANY_DETAILS_LOADING,
    FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS,
    FETCH_SINGLE_COMPANY_DETAILS_FAILURE,
    FETCH_SINGLE_COMPANY_DETAILS_LOADING,
    FETCH_SINGLE_COMPANY_DETAILS_SUCCESS,
    FETCH_VERY_BASIC_COMPANY_INFO_FAILURE,
    FETCH_VERY_BASIC_COMPANY_INFO_LOADING,
    FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS,
    FETCH_COMPANY_QUOTE_FAILURE,
    FETCH_COMPANY_QUOTE_LOADING,
    FETCH_COMPANY_QUOTE_SUCCESS,
    FETCH_INCOME_STATEMENT_FAILURE,
    FETCH_INCOME_STATEMENT_LOADING,
    FETCH_INCOME_STATEMENT_SUCCESS
} from '../../types/redux/CompaniesTypes';
import { ICompositeInfo, Sectors, SectorToCompanyMap, stateInitialToNameMap, StateToCompanyMap } from '../../types/Types';

export interface ICompaniesState {
    companiesList: Array<ICompanyInfo> | 'loading' | null,
    veryBasicCompanyInfo: Array<IVeryBasicCompanyInfo> | 'loading' | null | Error,
    companyDetailsMap: {
        [companyName: string]: IDetailedCompanyInfo | 'loading' | undefined | Error,
    }
    companySectorSummaries: ISummaryAverage[] | null,
    companyStateSummaries: ISummaryAverage[] | null,
    compositeInfo: ICompositeInfo | null,
    companyQuote: ICompanyQuote[] | 'loading' | null | Error,
    incomeStatement: IIncomeStatement[] | 'loading' | null | Error,
    error: string | null
}

const initialState: ICompaniesState = {
    companiesList: null,
    veryBasicCompanyInfo: null,
    companyDetailsMap: {},
    companySectorSummaries: null,
    companyStateSummaries: null,
    compositeInfo: null,
    companyQuote: null,
    incomeStatement: null,
    error: null
}

export default function(state: ICompaniesState = initialState, action: CompaniesActions): ICompaniesState {
    switch(action.type) {
        /* FETCH ALL FORTUNE 500 COMPANIES */
        case FETCH_FORTUNE_500_COMPANIES_LOADING:
        {
            const newState: ICompaniesState = {
                ...state,
                companiesList: 'loading'
            };
            return newState;
        }
        case FETCH_FORTUNE_500_COMPANIES_SUCCESS:
        {
            // Extract payload.
            const companiesList = action.payload;
            const companies = companiesList.map(company => ({...company, company: company.company.replace('&amp;', '&')}))
            // Calculate summaries by sector.
            const sectorSummaries: Array<ISummaryAverage> = [];
            const companiesBySector: SectorToCompanyMap = companiesList.reduce((sectorMap: SectorToCompanyMap, company: ICompanyInfo) => {
                if (sectorMap[company.sector as Sectors] === undefined) {
                    sectorMap[company.sector as Sectors] = [company];
                } else {
                    sectorMap[company.sector as Sectors] = sectorMap[company.sector as Sectors]?.concat([company]);
                }
                return sectorMap;
            }, {});
            const DISPLAYED_SECTORS: Array<Sectors> = ['Financials', 'Technology', 'Health Care', 'Retail', 'Energy'];
            for (const [sector, companyList] of Object.entries(companiesBySector)) {
                if (!DISPLAYED_SECTORS.includes(sector as Sectors)) continue;
                const numberOfCompanies = companyList?.length || 1;
                const totalRating = companyList?.reduce((total: number, company: ICompanyInfo) => total + company.gemmaq_rating, 0) || 0;
                const totalChange = companyList?.reduce((total: number, company: ICompanyInfo) => total + company.rating_change, 0) || 0;
                const bannerInfoSummary: ISummaryAverage = {
                    title: sector,
                    rating: Math.round(100 * totalRating / numberOfCompanies) / 100,
                    changeRating: Math.round(100 * totalChange / numberOfCompanies) / 100
                };
                sectorSummaries.push(bannerInfoSummary);
            }

            // Calculate summaires by state.
            const stateSummaries: Array<ISummaryAverage> = [];
            const companiesByState: StateToCompanyMap = companiesList.reduce((sectorMap: StateToCompanyMap, company: ICompanyInfo) => {
                if (company.state === undefined) return sectorMap;
                if (sectorMap[company.state] === undefined) {
                    sectorMap[company.state] = [company];
                } else {
                    sectorMap[company.state] = sectorMap[company.state]?.concat([company]);
                }
                return sectorMap;
            }, {});
            for (const [state, companyList] of Object.entries(companiesByState)) {
                // Get full name of state.
                const stateName = stateInitialToNameMap[state];
                if (stateName === undefined) continue;

                const numberOfCompanies = companyList?.length || 1;
                const totalRating = companyList?.reduce((total: number, company: ICompanyInfo) => total + company.gemmaq_rating, 0) || 0;
                const totalChange = companyList?.reduce((total: number, company: ICompanyInfo) => total + company.rating_change, 0) || 0;
                
                const bannerInfoSummary: ISummaryAverage = {
                    title: stateName,
                    rating: Math.round(100 * totalRating / numberOfCompanies) / 100,
                    changeRating: Math.round(100 * totalChange / numberOfCompanies) / 100
                };
                stateSummaries.push(bannerInfoSummary);
            }
            // Calculate Composite Info.
            const compositeInfo = getCompositeInfoFromCompanyList(companiesList);
            // Create new state.
            const newState: ICompaniesState = {
                ...state,
                companiesList: companies,
                companySectorSummaries: sectorSummaries,
                companyStateSummaries: stateSummaries,
                compositeInfo
            };
            return newState;
        }
        case FETCH_FORTUNE_500_COMPANIES_FAILURE:
        {
            const error = action.payload;
            const newState: ICompaniesState = {
                ...state,
                companiesList: null,
                companySectorSummaries: null,
                companyStateSummaries: null,
                error
            };
            return newState;
        }
        /* FETCH SINGLE COMPANY */
        case FETCH_SINGLE_COMPANY_DETAILS_LOADING: {
            const { companyName } = action.payload;
            const companyDetailsMapCopy = {...state.companyDetailsMap};
            companyDetailsMapCopy[companyName] = 'loading';
            const newState: ICompaniesState = {
                ...state,
                companyDetailsMap: companyDetailsMapCopy
            }
            return newState;
        }
        case FETCH_SINGLE_COMPANY_DETAILS_SUCCESS: {
            const companyDetails = action.payload;
            const companyName = companyDetails.company;
            const companyDetailsMapCopy = {...state.companyDetailsMap};
            companyDetailsMapCopy[companyName] = companyDetails;
            const newState: ICompaniesState = {
                ...state,
                companyDetailsMap: companyDetailsMapCopy
            }
            return newState;
        }
        case FETCH_SINGLE_COMPANY_DETAILS_FAILURE:
        {
            const { companyName, error } = action.payload;
            const companyDetailsMapCopy = {...state.companyDetailsMap};
            companyDetailsMapCopy[companyName] = new Error(error);
            const newState: ICompaniesState = {
                ...state,
                companyDetailsMap: companyDetailsMapCopy
            }
            return newState;
        }
        /* FETCH MULTIPLE COMPANIES DETAILS */
        case FETCH_MULTIPLE_COMPANY_DETAILS_LOADING: {
            const { companyNames } = action.payload;
            const companyDetailsMapCopy = {...state.companyDetailsMap};
            for (let companyName of companyNames) {
                companyDetailsMapCopy[companyName] = 'loading';
            }
            const newState: ICompaniesState = {
                ...state,
                companyDetailsMap: companyDetailsMapCopy
            };
            return newState;
        }
        case FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS: {
            const detailedCompanies = action.payload;
            const companyDetailsMapCopy = {...state.companyDetailsMap};
            for (let detailedCompany of detailedCompanies) {
                const { company, error_message } = detailedCompany;
                if (error_message === '') {
                    companyDetailsMapCopy[company] = detailedCompany;
                } else {
                    companyDetailsMapCopy[company] = new Error(error_message);
                }
            }
            const newState: ICompaniesState = {
                ...state,
                companyDetailsMap: companyDetailsMapCopy
            };
            return newState;
        }
        case FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE: {
            const { companyNames, error } = action.payload;
            const companyDetailsMapCopy = {...state.companyDetailsMap};
            for (let companyName of companyNames) {
                companyDetailsMapCopy[companyName] = new Error(error);
            }
            const newState: ICompaniesState = {
                ...state,
                companyDetailsMap: companyDetailsMapCopy
            };
            return newState;
        }
        /* FETCH VERY BASIC COMPANY INFO */
        case FETCH_VERY_BASIC_COMPANY_INFO_LOADING: {
            const newState: ICompaniesState = {
                ...state,
                veryBasicCompanyInfo: 'loading'
            }
            return newState;
        }
        case FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS: {
            const companies = action.payload;
            const newState: ICompaniesState = {
                ...state,
                veryBasicCompanyInfo: companies
            };
            return newState;
        }
        case FETCH_VERY_BASIC_COMPANY_INFO_FAILURE: {
            const errorMessage = action.payload;
            const newState: ICompaniesState = {
                ...state,
                veryBasicCompanyInfo: new Error(errorMessage)
            };
            return newState;
        }
        /* FETCH COMPANY QUOTE */
        case FETCH_COMPANY_QUOTE_LOADING: {
            const newState: ICompaniesState = {
                ...state,
                companyQuote: 'loading'
            }
            return newState;
        }
        case FETCH_COMPANY_QUOTE_SUCCESS: {
            const company = action.payload;
            const newState: ICompaniesState = {
                ...state,
                companyQuote: company
            }
            return newState;
        }
        case FETCH_COMPANY_QUOTE_FAILURE: {
            const error = action.payload;
            const newState: ICompaniesState = {
                ...state,
                companyQuote: new Error(error)
            }
            return newState;
        }
        /* FETCH INCOME STATEMENT */
        case FETCH_INCOME_STATEMENT_LOADING: {
            const newState: ICompaniesState = {
                ...state,
                incomeStatement: 'loading'
            }
            return newState;
        }
        case FETCH_INCOME_STATEMENT_SUCCESS: {
            const incomeStatement = action.payload;
            const newState: ICompaniesState = {
                ...state,
                incomeStatement
            }
            return newState;
        }
        case FETCH_INCOME_STATEMENT_FAILURE: {
            const error = action.payload;
            const newState: ICompaniesState = {
                ...state,
                incomeStatement: new Error(error)
            }
            return newState;
        }
        /* FETCH LIST OF DETAILED COMPANIES */
        default:
            return state;
    }
}
