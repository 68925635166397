import { ICompanyInfo, ICompanyQuote, IDetailedCompanyInfo, IIncomeStatement, IVeryBasicCompanyInfo } from '../../types/api/CompaniesTypes';
import { Dispatch } from '../../types/redux';
import { CompaniesActions, FETCH_FORTUNE_500_COMPANIES_FAILURE, FETCH_FORTUNE_500_COMPANIES_LOADING, FETCH_FORTUNE_500_COMPANIES_SUCCESS, FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE, FETCH_MULTIPLE_COMPANY_DETAILS_LOADING, FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS, FETCH_SINGLE_COMPANY_DETAILS_FAILURE, FETCH_SINGLE_COMPANY_DETAILS_LOADING, FETCH_SINGLE_COMPANY_DETAILS_SUCCESS, FETCH_VERY_BASIC_COMPANY_INFO_FAILURE, FETCH_VERY_BASIC_COMPANY_INFO_LOADING, FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS, FETCH_COMPANY_QUOTE_LOADING, FETCH_COMPANY_QUOTE_SUCCESS, FETCH_COMPANY_QUOTE_FAILURE, FETCH_INCOME_STATEMENT_LOADING, FETCH_INCOME_STATEMENT_SUCCESS, FETCH_INCOME_STATEMENT_FAILURE } from '../../types/redux/CompaniesTypes';

const FINANCE_API_KEY = 'a89e1b73a2c481d7dff017ea860d5b40';

/* FORTUNE 500 REQUEST */

// Thunk function.
const requestFortune500CompaniesThunk = async (dispatch: Dispatch) => {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth() + 1;
    const currentYear = now.getFullYear();
    
    const ratingDate =            currentYear.toString().padStart(4, '0') + currentMonth.toString().padStart(2, '0') + currentDay.toString().padStart(2, '0');
    const changeSinceDate = (currentYear - 1).toString().padStart(4, '0') + currentMonth.toString().padStart(2, '0') + currentDay.toString().padStart(2, '0');
    const url = `https://9e5wkvg27j.execute-api.us-east-2.amazonaws.com/test/fortune500?changeSinceDate=${changeSinceDate}&ratingDate=${ratingDate}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const responseBody = await response.json();
            dispatch(requestFortune500CompaniesSuccess(responseBody));
        } else {
            dispatch(requestFortune500CompaniesFailure('Request failed'));
        }
    } catch (e) {
        dispatch(requestFortune500CompaniesFailure('Request failed'));
    }
}

// Loading function.
const requestFortune500CompaniesLoading = () => ({
    type: FETCH_FORTUNE_500_COMPANIES_LOADING
})

// Success function.
const requestFortune500CompaniesSuccess = (companies: Array<ICompanyInfo>): CompaniesActions => ({
    type: FETCH_FORTUNE_500_COMPANIES_SUCCESS,
    payload: companies
});

// Failure function.
const requestFortune500CompaniesFailure = (error: string): CompaniesActions => ({
    type: FETCH_FORTUNE_500_COMPANIES_FAILURE,
    payload: error
});

// Exported function.
export const requestFortune500Companies = () => {
    return (dispatch: Dispatch) => {
        dispatch(requestFortune500CompaniesLoading());
        requestFortune500CompaniesThunk(dispatch);
    }
}

/* SINGLE COMPANY REQUEST */

// Thunk function.
const requestSingleCompanyInfoThunk = async (dispatch: Dispatch, companyName: string) => {
    const url = `https://45y9zl3rjd.execute-api.us-east-2.amazonaws.com/test/${companyName}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const responseBody = await response.json();
            dispatch(requestSingleCompanyInfoSuccess(responseBody));
        } else {
            dispatch(requestSingleCompanyInfoFailure(companyName, 'Request failed'));
        }
    } catch (e) {
        dispatch(requestSingleCompanyInfoFailure(companyName, 'Request failed'));
    }
}

// Loading function.
const requestSingleCompanyInfoLoading = (companyName: string): CompaniesActions => ({
    type: FETCH_SINGLE_COMPANY_DETAILS_LOADING,
    payload: {
        companyName
    }
});

// Success function.
const requestSingleCompanyInfoSuccess = (company: IDetailedCompanyInfo): CompaniesActions => ({
    type: FETCH_SINGLE_COMPANY_DETAILS_SUCCESS,
    payload: company
});

// Failure function.
const requestSingleCompanyInfoFailure = (companyName: string, error: string): CompaniesActions => ({
    type: FETCH_SINGLE_COMPANY_DETAILS_FAILURE,
    payload: {
        companyName,
        error
    }
});

// Exported function.
export const requestSingleCompanyInfo = (companyName: string) => {
    return (dispatch: Dispatch) => {
        dispatch(requestSingleCompanyInfoLoading(companyName))
        requestSingleCompanyInfoThunk(dispatch, companyName);
    }
}

/* COMPANY QUOTE REQUEST */

// Thunk function.
const requestCompanyQuoteThunk = async (dispatch: Dispatch, symbol: string) => {
    const url = `https://financialmodelingprep.com/api/v3/quote/${symbol}?apikey=${FINANCE_API_KEY}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const responseBody = await response.json();
            dispatch(requestCompanyQuoteSuccess(responseBody));
        } else {
            dispatch(requestCompanyQuoteFailure('Request failed'));
        }
    } catch (e) {
        dispatch(requestCompanyQuoteFailure('Request failed'));
    }
}

// Loading function.
const requestCompanyQuoteLoading = (): CompaniesActions => ({
    type: FETCH_COMPANY_QUOTE_LOADING
});

// Success function.
const requestCompanyQuoteSuccess = (company: ICompanyQuote[]): CompaniesActions => ({
    type: FETCH_COMPANY_QUOTE_SUCCESS,
    payload: company
});

// Failure function.
const requestCompanyQuoteFailure = (error: string): CompaniesActions => ({
    type: FETCH_COMPANY_QUOTE_FAILURE,
    payload: error
});

// Exported function.
export const requestCompanyQuote = (symbol: string) => {
    return (dispatch: Dispatch) => {
        dispatch(requestCompanyQuoteLoading())
        requestCompanyQuoteThunk(dispatch, symbol);
    }
}

/* INCOME STATEMENT REQUEST */

// Thunk function.
const requestIncomeStatementThunk = async (dispatch: Dispatch, symbol: string) => {
    const url = `https://financialmodelingprep.com/api/v3/income-statement/${symbol}?apikey=${FINANCE_API_KEY}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const responseBody = await response.json();
            dispatch(requestIncomeStatementSuccess(responseBody));
        } else {
            dispatch(requestIncomeStatementFailure('Request failed'));
        }
    } catch (e) {
        dispatch(requestIncomeStatementFailure('Request failed'));
    }
}

// Loading function.
const requestIncomeStatementLoading = (): CompaniesActions => ({
    type: FETCH_INCOME_STATEMENT_LOADING
});

// Success function.
const requestIncomeStatementSuccess = (statement: IIncomeStatement[]): CompaniesActions => ({
    type: FETCH_INCOME_STATEMENT_SUCCESS,
    payload: statement
});

// Failure function.
const requestIncomeStatementFailure = (error: string): CompaniesActions => ({
    type: FETCH_INCOME_STATEMENT_FAILURE,
    payload: error
});

// Exported function.
export const requestIncomeStatement = (symbol: string) => {
    return (dispatch: Dispatch) => {
        dispatch(requestIncomeStatementLoading())
        requestIncomeStatementThunk(dispatch, symbol);
    }
}

/* MULTIPLE COMPANIES DETAILS */

// Thunk function.
const requestMultipleCompaniesDetailsThunk = async (dispatch: Dispatch, companyNames: string[]) => {
    const url = `https://45y9zl3rjd.execute-api.us-east-2.amazonaws.com/test`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(companyNames)
        });
        if (response.ok) {
            const responseBody = await response.json();
            dispatch(requestMultipleCompaniesDetailsSuccess(responseBody));
        } else {
            dispatch(requestMultipleCompaniesDetailsFailure(companyNames, 'Request failed'));
        }
    } catch (e) {
        dispatch(requestMultipleCompaniesDetailsFailure(companyNames, 'Request failed'));
    }
}

// Loading function.
const requestMultipleCompaniesDetailsLoading = (companyNames: string[]): CompaniesActions => ({
    type: FETCH_MULTIPLE_COMPANY_DETAILS_LOADING,
    payload: {
        companyNames
    }
});

// Success function.
const requestMultipleCompaniesDetailsSuccess = (companies: IDetailedCompanyInfo[]): CompaniesActions => ({
    type: FETCH_MULTIPLE_COMPANY_DETAILS_SUCCESS,
    payload: companies
});

// Failure function.
const requestMultipleCompaniesDetailsFailure = (companyNames: string[], error: string): CompaniesActions => ({
    type: FETCH_MULTIPLE_COMPANY_DETAILS_FAILURE,
    payload: {
        companyNames,
        error
    }
});

// Exported function.
export const requestMultipleCompaniesDetails = (companyNames: string[]) => {
    return (dispatch: Dispatch) => {
        dispatch(requestMultipleCompaniesDetailsLoading(companyNames))
        requestMultipleCompaniesDetailsThunk(dispatch, companyNames);
    }
}

/* GET VERY BASIC COMPANY INFO */

// Thunk function.
const requestVeryBasicCompanyInfoThunk = async (dispatch: Dispatch) => {
    const url = `https://9e5wkvg27j.execute-api.us-east-2.amazonaws.com/test/namemnemonicarray`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const responseBody = await response.json();
            dispatch(requestVeryBasicCompanyInfoSuccess(responseBody));
        } else {
            dispatch(requestVeryBasicCompanyInfoFailure('Request failed'));
        }
    } catch (e) {
        dispatch(requestVeryBasicCompanyInfoFailure('Request failed'));
    }
}

// Loading function.
const requestVeryBasicCompanyInfoLoading = (): CompaniesActions => ({
    type: FETCH_VERY_BASIC_COMPANY_INFO_LOADING
});

// Success function.
const requestVeryBasicCompanyInfoSuccess = (company: Array<IVeryBasicCompanyInfo>): CompaniesActions => ({
    type: FETCH_VERY_BASIC_COMPANY_INFO_SUCCESS,
    payload: company
});

// Failure function.
const requestVeryBasicCompanyInfoFailure = (error: string): CompaniesActions => ({
    type: FETCH_VERY_BASIC_COMPANY_INFO_FAILURE,
    payload: error
});

// Exported function.
export const requestVeryBasicCompanyInfo = () => {
    return (dispatch: Dispatch) => {
        dispatch(requestVeryBasicCompanyInfoLoading())
        requestVeryBasicCompanyInfoThunk(dispatch);
    }
}