// Package imports:
import React from 'react';
// Style imports:
import './Dashboard.scss';
// Component imports:
import PanelWrapper from './PanelWrapper/PanelWrapper';
import CompositePanel from './CompositePanel/CompositePanel';
import BiggestCompaniesList from './BiggestCompaniesList/BiggestCompaniesList';
import GemmaqSectorAverage from './GemmaqSectorAverage/GemmaqSectorAverage';
import GemmaqStateAverage from './GemmaqStateAverage/GemmaqStateAverage';
import TopRatedList from './TopRatedList/TopRatedList';
import BottomRatedList from './BottomRatedList/BottomRatedList';

class Dashboard extends React.Component {
    render () {
        return (
            <div id='Dashboard'>
                <PanelWrapper
                    title='GEMMAQ Gender Lens'
                    id='CompositePanelWrapper'
                >
                    <CompositePanel />
                </PanelWrapper>

                <PanelWrapper
                    title='Fortune 500 Companies'
                    id='BiggestCompaniesListWrapper'
                >
                    <BiggestCompaniesList />
                </PanelWrapper>

                <div id='GemmaqAveragesWrapper'>
                    <PanelWrapper
                        title='GEMMAQ Sector Average'
                        id='GemmaqSectorAverageWrapper'
                    >
                        <GemmaqSectorAverage />
                    </PanelWrapper>

                    <PanelWrapper
                        title='GEMMAQ State Average'
                        id='GemmaqStateAverageWrapper'
                    >
                        <GemmaqStateAverage />
                    </PanelWrapper>
                </div>

                <PanelWrapper
                    title='Top 10'
                    id='TopRatedListWrapper'
                >
                    <TopRatedList />
                </PanelWrapper>

                <PanelWrapper
                    title='Bottom 10'
                    id='BottomRatedListWrapper'
                >
                    <BottomRatedList />
                </PanelWrapper>
            </div>
        )
    }
}

export default Dashboard;
