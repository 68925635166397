// Package imports:
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
// Style imports:
import './App.scss';
// Component imports:
import NavigationBar from './NavigationBar/NavigationBar';
import SearchPage from './SearchPage/SearchPage';
import Footer from './Footer/Footer';
import Dashboard from './Dashboard/Dashboard';
import CompanyProfile from './CompanyProfile/CompanyProfile';
import Methodology from './Methodology/Methodology';
import AboutUs from './AboutUs/AboutUs';
import Watchlist from './Watchlist/Watchlist';
import ToastControlCenter from './ControlCenters/ToastControlCenter';
// Action imports:
import { requestFortune500Companies } from '../redux-store/actions/companiesActions';
import { requestWatchlist } from '../redux-store/actions/watchlistActions';
import { setLoginInfo } from '../redux-store/actions/loginActions';
import { RootState } from '../redux-store';
import { isUserLoggedIn } from '../services/loginUtils';

type ReduxProps = ConnectedProps<typeof connector>; 
type Props = ReduxProps;

const App: React.FC<Props> = ({
    watchlist,
    idToken,
    companiesList,
    isLoggedIn,
    setLoginInfo,
    requestWatchlist,
    requestFortune500Companies
}) => {
    useEffect(() => {
        const { location } = window;
        if (location.hash) {
            const parsed = queryString.parse(location.hash);
            const { access_token, expires_in, id_token } = parsed;
            if (
                typeof access_token === 'string'
                && typeof expires_in === 'string'
                && typeof id_token === 'string'
            ) {
                setLoginInfo(access_token, expires_in, id_token);
                // Remove access and id token from browser query.
                window.history.replaceState({}, document.title, '/');
            }
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
    })

    const fetchData = () => {
        if (watchlist === null && idToken !== null && isLoggedIn) {
            requestWatchlist(idToken);
        }
        if (companiesList === null) {
            requestFortune500Companies();
        }
    }

    const getNavBarVariant = () => {
        const path = (window.location.pathname + '/').split('/')[1];
        switch (path) {
            case '':
            case 'about-us':
                return 'with logo';
            default:
                return 'with logo and search bar';
        }
    }

    return (
        <div id='App'>
            <NavigationBar variant={getNavBarVariant()} />
            <Switch>
                <Route
                    path='/watchlist'
                    render={() => {
                        if (isLoggedIn) {
                            return <Watchlist />
                        } else {
                            return <Redirect to='/' />
                        }
                    }}
                />
                <Route
                    path='/about-us'
                    render={() => <AboutUs />}
                />
                <Route
                    path='/about-measures'
                    render={() => <Methodology />}
                />
                <Route
                    path='/company/:id'
                    render={(props) => {
                        const companyName = props.match.params.id;
                        return (
                            <CompanyProfile companyName={companyName} />
                        );
                    }}
                />
                <Route
                    path='/SearchPage'
                    render={() => <SearchPage />}
                />
                <Route
                    path='/'
                    render={() => <Dashboard />}
                />
            </Switch>
            <ToastControlCenter />
            <Footer />
        </div>
    );
}

function mapStateToProps({ loginReducer, watchlistReducer, companiesReducer }: RootState) {
    const { idToken, accessToken, expiresAt_ms } = loginReducer;
    const { watchlist } = watchlistReducer;
    const { companiesList } = companiesReducer;
    const isLoggedIn = isUserLoggedIn(accessToken, idToken, expiresAt_ms);
    return {
        idToken,
        watchlist,
        companiesList,
        isLoggedIn
    }
}

const connector = connect(mapStateToProps, { requestFortune500Companies, setLoginInfo, requestWatchlist });
export default connector(App);
