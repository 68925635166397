// Reducer imports:
import { combineReducers } from 'redux';
import newsFeedReducer, { INewsFeedState } from './reducers/newsFeedReducer';
import companiesReducer, { ICompaniesState } from './reducers/companiesReducer';
import loginReducer, { ILoginState } from './reducers/loginReducer';
import watchlistReducer, { IWatchlistState } from './reducers/watchlistReducer';

export type RootState = {
    newsFeedReducer: INewsFeedState,
    companiesReducer: ICompaniesState,
    loginReducer: ILoginState,
    watchlistReducer: IWatchlistState
}

const rootReducer = combineReducers({
    newsFeedReducer,
    companiesReducer,
    loginReducer,
    watchlistReducer
});

export default rootReducer;