// Package imports:
import React, { useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
// Style imports:
import './NavigationBar.scss';
// Component imports:
import Navbar from 'react-bootstrap/Navbar';
import SearchBar from '../SearchBar/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import NavigationDropdown from './NavigationDropdown';
// Service imports:
import { isUserLoggedIn } from '../../services/loginUtils';
// Action imports:
import { logOut } from '../../redux-store/actions/loginActions';
// Type imports:
import { RootState } from '../../redux-store';

type ReduxProps = ConnectedProps<typeof connector>;
interface IOwnProps {
    variant: 'no logo' | 'with logo' | 'with logo and search bar'
}
type Props = ReduxProps & IOwnProps;

const NavigationBar: React.FC<Props> = ({
    variant,
    isLoggedIn,
    username,
    logOut
}) => {
    const [ isMobileHamburgerMenuExpanded, setIsMobileHamburgerMenuExpanded ] = useState(false);

    const buttons = useMemo(() => {
        const buttons = [{
            text: 'Join Our Mission',
            href: 'https://gemmaq.co/gemmaqmember'
        },{
            text: 'Vision for Equality',
            href: 'https://gemmaq.co/mission'
        },{
            text: 'Gender Lens Insights',
            href: 'https://gemmaq.co/Insights',
            subItems: [{
                label: 'Monthly Reports',
                link: 'https://gemmaq.co/Insights'
            }, {
                label: 'GemmaQ GenderLens',
                link: 'https://www.gemmaq.io/fortune500'
            }, {
                label: 'Newsletter USA',
                link: 'https://gemmaq.co/newsletter'
            }, {
                label: 'Newsletter Iceland',
                link: 'https://gemmaq.co/gemmaqiceland'
            }, {
                label: 'Gender Lens Iceland',
                link: 'https://keldan.is/Markadir/Gemmaq'
            }, {
                label: 'Methodology: Join',
                link: 'https://www.gemmaq.io/about-measures'
            }, {
                label: 'Learn',
                link: 'https://gemmaq.co/learn'
            }]
        }];
        if (isLoggedIn) buttons.push({
            text: 'Watchlist',
            href: '/watchlist'
        });
        return buttons;
    }, [ isLoggedIn ]);

    return (
        <Navbar id='NavigationBar' className={cx({ 'is-expanded': isMobileHamburgerMenuExpanded })}>
            <div className='NavigationBarWrapper'>
                {/* Left */}
                <div className='NavigationBarLeftArea'>
                    {(variant === 'no logo' && !isMobileHamburgerMenuExpanded) ? null : (
                        <Navbar.Brand as='a' href='/'>
                            <img
                                src={require('../../assets/gemmaqlogowithname.png')}
                                alt='Gemmaq Logo'
                            />
                        </Navbar.Brand>
                    )}
                </div>
                {/* Center and Right */}
                <div className='NavigationBarCenterAndRightArea'>
                    <div className='NavigationBarCraTop'>
                        <div className='ButtonContainer'>
                            <div className='NavigationHamburgerButtonWrapper'>
                                <button
                                    className='NavigationHamburgerButton'
                                    onClick={() => {
                                        const hideOverflowClass = 'HideOverflow';
                                        // If expanded, close.
                                        if (isMobileHamburgerMenuExpanded) {
                                            // Remove HideOverflow from html tag.
                                            const element = document.firstElementChild;
                                            if (element) {
                                                const classes = element.className?.split(' ') ?? [];
                                                classes.splice(classes.indexOf(hideOverflowClass));
                                                const className = classes.join(' ');
                                                element.className = className;
                                            }
                                            setIsMobileHamburgerMenuExpanded(false);
                                        }
                                        // If closed, expand.
                                        else {
                                            // Add Hide overflow to the html tag.
                                            const element = document.firstElementChild;
                                            if (element) {
                                                const classes = element.className?.split(' ') ?? [];
                                                classes.push(hideOverflowClass);
                                                const className = classes.join(' ');
                                                element.className = className;
                                            }
                                            setIsMobileHamburgerMenuExpanded(true);
                                        }
                                    }}
                                >
                                    {(isMobileHamburgerMenuExpanded)
                                    ? (
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            title='Close'
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faBars}
                                            title='Menu'
                                        />
                                    )}
                                </button>
                            </div>
                            <div className='NavBarLinkWrapper'>
                                {buttons.map(({ text, href, subItems }, index) => (
                                    subItems
                                    ? <NavigationDropdown
                                        key={index}
                                        label={text}
                                        navItems={subItems}
                                    />
                                    : <a
                                        key={index}
                                        href={href}
                                        className='NavBarLink'
                                    >
                                        {text}
                                    </a>
                                ))}
                            </div>
                            {(isLoggedIn) ? (
                                <span
                                    className='SignInButton'
                                    onClick={() => {
                                        logOut()
                                        window.location.reload();
                                    }}
                                >
                                    <span className='SignInText'>
                                        Sign out
                                    </span>
                                </span>
                            ) : (
                                <a
                                    className='SignInButton'
                                    href={`https://gemmaq.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=6p09n0lll0o1j4rn0aa7ru8fgh&redirect_uri=${window.location.origin}`}
                                    
                                >
                                    <span className='SignInText'>
                                        Sign in
                                    </span>
                                </a>
                            )}
                            
                        </div>
                        <div className='UsernameContainer'>
                            {(isLoggedIn && username) && <span className='Username'>Hi, {username}</span>}
                        </div>
                    </div>
                    <div className='NavigationBarCraBottom'>
                        {(variant === 'with logo and search bar') ? (
                            <div className='SearchBarContainer'>
                                <SearchBar isBookmarkable />
                            </div>
                        ) : null}
                        <div className='MobileContentContainer'>
                            <div className='UsernameContainer'>
                                {(isLoggedIn && username) && <span className='Username'>Hi, {username}</span>}
                            </div>
                            <div className='NavBarLinkContainer'>
                                {buttons.map(({ text, href, subItems }, index) => (
                                    subItems
                                    ? <NavigationDropdown
                                        key={index}
                                        label={text}
                                        navItems={subItems}
                                    />
                                    : <a
                                        key={index}
                                        href={href}
                                        className='NavBarLink'
                                    >
                                        {text}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    );
}

function mapStateToProps({ loginReducer }: RootState) {
    const { accessToken, expiresAt_ms, idToken, username } = loginReducer
    const isLoggedIn = isUserLoggedIn(accessToken, idToken, expiresAt_ms);
    return {
        isLoggedIn,
        username
    }
}

const connector = connect(mapStateToProps, { logOut });
export default connector(NavigationBar);
