// Package imports:
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './ErrorToast.scss';
// Component imports:
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
// Action imports:
import { removeErrorToast } from '../../redux-store/actions/watchlistActions';
// Type imports:
import { IErrorToast } from '../../types/api/WatchlistTypes';

interface IOwnProps {
    errorToastInfo: IErrorToast
};
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps & IOwnProps;

const REMOVE_TOAST_AFTER_N_MS = 3000;

const ErrorToast: React.FC<Props> = ({ errorToastInfo, removeErrorToast }) => {
    const { id, header, error } = errorToastInfo;

    const [ showToast, setShowToast ] = useState(true);

    // On Mount, set timeout to kill the toast.
    useEffect(() => {
        // We want to fade out first to get animation.
        const fadeTimeoutId = window.setTimeout(() => {
            setShowToast(false);
        }, REMOVE_TOAST_AFTER_N_MS);
        // Then after animation, we remove it from the array.
        const removeTimeoutId = window.setTimeout(() => {
            removeErrorToast(id);
        }, REMOVE_TOAST_AFTER_N_MS + 2000);
        // Clean up.
        return () => {
            window.clearTimeout(fadeTimeoutId);
            window.clearTimeout(removeTimeoutId);
        }
    });

    return (
        <Toast
            key={id}
            show={showToast}
            className='ErrorToast'
            onClose={() => {
                removeErrorToast(id);
            }}
        >
            <Toast.Header
                closeButton={false}
            >
                <b>
                    {header}
                </b>
                <FontAwesomeIcon
                    icon={faTimes}
                    className='FaTimes'
                    onClick={() => {
                        removeErrorToast(id);
                    }}
                />
            </Toast.Header>
            <Toast.Body>
                <p>
                    Reason: {error.message}
                </p>
            </Toast.Body>
        </Toast>
    )
}

const connector = connect(null, { removeErrorToast });
export default connector(ErrorToast);