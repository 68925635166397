// Package imports:
import React from 'react';
// Component imports:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

type Props = React.HTMLProps<HTMLParagraphElement> & { onClick: any };

export const CustomToggle = React.forwardRef<HTMLParagraphElement, Props>(({
    children,
    onClick
}, ref) => (
    <p
        className='TableHeader'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        {' '}
        <FontAwesomeIcon icon={faChevronDown} />
    </p>
));