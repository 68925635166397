// Package imports:
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './ToastControlCenter.scss';
// Component imports:
import ErrorToast from './ErrorToast';
// Type imports:
import { RootState } from '../../redux-store';

interface IOwnProps {};
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps & IOwnProps;

const ToastControlCenter: React.FC<Props> = ({ errorToasts }) => {
    const errorToastsJsx = errorToasts.map((errorToastInfo) => (
        <ErrorToast
            errorToastInfo={errorToastInfo}
        />
    ))
    
    return (
        <div id='ToastControlCenter'>
            {errorToastsJsx}
        </div>
    );
}

function mapStateToProps({ watchlistReducer }: RootState) {
    const { errorToasts } = watchlistReducer;
    return {
        errorToasts
    }
}

const connector = connect(mapStateToProps);
export default connector(ToastControlCenter);