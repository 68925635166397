// Package imports:
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// Style imports:
import './Watchlist.scss';
// Component imports:
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GemmaqChart from '../Common/GemmaqChart';
import WatchlistIcon from '../Common/WatchlistIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faFemale, faMale } from '@fortawesome/pro-light-svg-icons';
// Service imports:
import { formatNumberTo1Decimal, getCompositeInfoFromCompanyList, roundToHalfs } from '../../services/utils';
// Action imports:
import { requestMultipleCompaniesDetails } from '../../redux-store/actions/companiesActions';
// Type imports:
import { RootState } from '../../redux-store';
import { Gender, IDetailedCompanyInfo } from '../../types/api/CompaniesTypes';

interface ICompanyAndError {
    company: string,
    mnemonic: string,
    error: Error
}

type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps;

const Watchlist: React.FC<Props> = ({
    watchlist,
    companyDetailsMap,
    companiesList,
    requestMultipleCompaniesDetails
}) => {
    useEffect(() => {
        fetchData();
    })

    const fetchData = () => {
        if (watchlist === 'loading' || watchlist === null || watchlist instanceof Error) return;
        const unfetchedDetailedCompanies: string[] = [];
        for (let watchlistItem of watchlist) {
            const companyName = watchlistItem.name;
            if (companyDetailsMap[companyName] === undefined) {
                unfetchedDetailedCompanies.push(companyName);
            }
        }
        if (unfetchedDetailedCompanies.length > 0) {
            requestMultipleCompaniesDetails(unfetchedDetailedCompanies);
        }
    }

    const displayWatchlistTable = (companiesInWatchlist: IDetailedCompanyInfo[], companiesInWatchlistWithErrors: ICompanyAndError[]) => {
        const allCompanies = (companiesList === null || companiesList === 'loading')
            ? []
            : [...companiesList];

        const watchlistJsxRows = companiesInWatchlist.map((companyInfo, index) => {
            const companyDetailsFromAllCompanies = allCompanies.find(company => (
                company.company === companyInfo.company
                && company.mnemonic === companyInfo.mnemonic
            ));
            let companyRatingChange = roundToHalfs(companyDetailsFromAllCompanies?.rating_change);
            const { ratingChange, ratingChangeClassName } = (companyRatingChange === null)
                ? {
                    ratingChange: '-',
                    ratingChangeClassName: undefined
                } :
                (companyRatingChange < 0)
                ? {
                    ratingChange: companyRatingChange,
                    ratingChangeClassName: 'Negative'
                } :
                (companyRatingChange > 0)
                ? {
                    ratingChange: '+' + companyRatingChange,
                    ratingChangeClassName: 'Positive'
                } : {
                    ratingChange: '+' + companyRatingChange,
                    ratingChangeClassName: undefined
                };
            return (
                <tr key={index}>
                    <td className='TextColumn TableTextLeft'>
                        <WatchlistIcon
                            companyName={companyInfo.company}
                            companyMnemonic={companyInfo.mnemonic}
                        />
                        <a href={`/company/${companyInfo.company}`}>
                            {companyInfo.company}
                        </a>
                    </td>
                    <td className='TableTextRight'>
                        {roundToHalfs(companyInfo.gemmaq_rating) ?? '-'}
                        <span className={ratingChangeClassName}>{ratingChange}</span>
                    </td>
                </tr>
            );
        });

        const watchlistErrorJsxRows = companiesInWatchlistWithErrors.map(({ company, mnemonic, error }, index) => (
            <tr key={index}>
                <td className='TextColumn TableTextLeft'>
                    <WatchlistIcon
                        companyName={company}
                        companyMnemonic={mnemonic}
                    />
                    <a href={`/company/${company}`}>
                        {company}
                    </a>
                </td>
                <td>
                    <OverlayTrigger
                        placement='left'
                        trigger='hover'
                        overlay={
                            <Tooltip
                                id={`WarningFor${mnemonic}`}
                            >
                                {error.message}
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faExclamationTriangle} className='Negative' />
                    </OverlayTrigger>
                    <span>
                        -
                    </span>
                </td>
            </tr>
        ))

        return (
            <div className='WatchlistTableWrapper'>
                <div className='TableHeaderWrapper'>
                    <p className='TableHeader FirstTableHeader'>
                        Company
                    </p>
                    <div className='TableHeaderJustifyRight'>
                        <p className='TableHeader LastTableHeader'>
                            Gemmaq Rating
                        </p>
                    </div>
                </div>
                <Table>
                    <tbody>
                        {watchlistJsxRows}
                        {watchlistErrorJsxRows}
                    </tbody>
                </Table>
            </div>
        );
    }

    const displayWatchlistGemmaqChart = (companiesInWatchlist: IDetailedCompanyInfo[]) => {
        const compositeInfo = getCompositeInfoFromCompanyList(companiesInWatchlist);
        if (compositeInfo === null) {
            return (
                <p>No Companies in Watchlist</p>
            );
        }

        const {
            averageRating,
            averagePercentCeo,
            averagePercentChairman,
            averageTopExecutives,
            averageBoardOfDirectors
        } = compositeInfo;

        return (
            <div
                className='ChartWrapper'
            >
                <GemmaqChart
                    rating={roundToHalfs(averageRating)}
                    ceo={averagePercentCeo}
                    chairman={averagePercentChairman}
                    topExecutives={averageTopExecutives}
                    boardOfDirectors={averageBoardOfDirectors}
                    precise={true}
                />
            </div>
        );
    }

    const displayCeoChairCards = (companiesInWatchlist: IDetailedCompanyInfo[]) => {
        let totalWomenCeos = 0;
        let totalCeos = 0;
        let totalWomenChairs = 0;
        let totalChairs = 0;

        for (let company of companiesInWatchlist) {
            for (let ceo of company.ceo) {
                if (ceo.gender === 'Female') totalWomenCeos++;
                totalCeos++;
            }
            for (let chair of company.chairman) {
                if (chair.gender === 'Female') totalWomenChairs++;
                totalChairs++;
            }
        }
        
        const womenCeosPercent = (totalWomenCeos / totalCeos);
        const womenChairsPercent = (totalWomenChairs / totalChairs);

        const genderForCeoCard: Gender = (womenCeosPercent >= 0.5) ? 'Female' : 'Male';
        const genderForChairCard: Gender = (womenChairsPercent >= 0.5) ? 'Female' : 'Male';

        const displayCeoPercent = Math.round(womenCeosPercent * 100);
        const displayChairPercent = Math.round(womenChairsPercent * 100);

        return (
            <div className='GenderCardWrapper'>
                <div className={`GenderCard ${genderForCeoCard}`}>
                    <div className='GenderCardTitle'>
                        CEOs
                    </div>
                    <div className='GenderCardName'>
                        {displayCeoPercent}% Women
                    </div>
                    <div className='GenderCardIcon'>
                        {
                            (genderForCeoCard === 'Male')
                            ? <FontAwesomeIcon icon={faMale} size='2x' className='FaMale' />
                            : <FontAwesomeIcon icon={faFemale} size='2x' className='FaFemale' />
                        }
                    </div>
                </div>
                <div className='GenderCardDivider'></div>
                <div className={`GenderCard ${genderForChairCard}`}>
                    <div className='GenderCardTitle'>
                        Chairs
                    </div>
                    <div className='GenderCardName'>
                        {displayChairPercent}% Women
                    </div>
                    <div className='GenderCardIcon'>
                    {
                            (genderForChairCard === 'Male')
                            ? <FontAwesomeIcon icon={faMale} size='2x' className='FaMale' />
                            : <FontAwesomeIcon icon={faFemale} size='2x' className='FaFemale' />
                        }
                    </div>
                </div>
            </div>
        );
    }

    const getUsaRanking = (thisCompaniesRating: number) => {
        if (companiesList === null || companiesList === 'loading') return null;
        const allCompanies = [...companiesList];

        allCompanies.sort((a,b) => {
            // Rating is sorted in ascending order.
            if (a.gemmaq_rating < b.gemmaq_rating) return -1;
            if (a.gemmaq_rating > b.gemmaq_rating) return 1;
            return 0;
        });

        let index = 0;
        for (; index < allCompanies.length; index++) {
            if (thisCompaniesRating < allCompanies[index].gemmaq_rating) break;
        }

        return (100 * index / allCompanies.length);
    }

    const displayGemmaqRatings = (companiesInWatchlist: IDetailedCompanyInfo[]) => {
        let totalRating = 0;
        let totalBoardRating = 0;
        let totalExecRating = 0;
        // let totalChange = 0;
        companiesInWatchlist.forEach(companyInfo => {
            totalRating += companyInfo.gemmaq_rating;
            totalBoardRating += companyInfo.board_sub_rating;
            totalExecRating += companyInfo.executive_sub_rating;
            // totalChange += companyInfo.rating_change;
        });
        const averageRating = totalRating / companiesInWatchlist.length;
        const averageBoardRating = totalBoardRating / companiesInWatchlist.length;
        const averageExecRating = totalExecRating / companiesInWatchlist.length;
        // const averageChange = totalChange / companiesInWatchlist.length;

        const usaRanking = getUsaRanking(averageRating);

        const ratings = [{
            title: 'Total',
            rating: roundToHalfs(averageRating),
            hoverTitle: `Total GEMMAQ Score`
        }, {
            title: 'Board',
            rating: roundToHalfs(averageBoardRating),
            hoverTitle: `Board of Directors GEMMAQ Score`
        }, {
            title: 'Executives',
            rating: roundToHalfs(averageExecRating),
            hoverTitle: `Top Executives GEMMAQ Score`
        // }, {
        //     title: 'Change',
        //     rating: roundToHalfs(averageChange)
        }, {
            title: 'US Ranking',
            rating: usaRanking,
            hoverTitle: `How your watchlist compares to US Companies`
        }];
        return (
            <div className='GemmaqRatingsWrapper'>
                <div className='GemmaqRatingsHeader'>
                    <div>GEMMAQ Ratings (0-10)</div>
                    <div>
                        <a href='/about-measures'>
                            <FontAwesomeIcon icon={faChevronCircleRight} className='FaChevronCircleRight' />
                            Methodology
                        </a>
                    </div>
                </div>
                <div className='GemmaqRatings'>
                    {
                        ratings.map(({ rating, title, hoverTitle }, index) => (
                            <div
                                className='GemmaqRatingsCard'
                                key={index}
                                title={hoverTitle}
                            >
                                <div className='Rating'>
                                    {
                                        // Case: US Ranking.
                                        (title === 'US Ranking')
                                        ? ((rating === null) ? '-' : ((Math.floor(rating) || 1) + '%'))
                                        // Case: Default
                                        : formatNumberTo1Decimal(rating, (title === 'Change'))
                                    }
                                </div>
                                <div className='Title'>
                                    {title}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    const displayWatchlistInfo = () => {
        if (watchlist instanceof Error) {
            return (
                <Row>
                    <Col>
                        <div className='ErrorWrapper'>
                            Error: {watchlist.message}
                        </div>
                    </Col>
                </Row>
            );
        }

        if (watchlist === null || watchlist === 'loading') {
            return (
                <Row>
                    <Col>
                        <div className='LoadingWrapper'>
                            <h3>Loading...</h3>
                        </div>
                    </Col>
                </Row>
            );
        }

        if (watchlist.length === 0) {
            return (
                <Row>
                    <Col>
                        <h3>No Companies in Watchlist</h3>
                    </Col>
                </Row>
            );
        }

        // What I'm trying to accomplish here is the following:
        // If we are still waiting on any data: show the loading screen
        // If there was a network error, or some event whereby all data is erronous, display error screen.
        // Lastly, if we receive all (or some) companies' data, display those companies and their averages
        // For the companies with errors internal to the gemmaq server, show some icon next to the company
        // to indicate that their numbers are not part of the averages.
        const watchlistCompanyDetailsList: IDetailedCompanyInfo[] = [];
        const watchlistCompaniesWithErrors: ICompanyAndError[] = [];
        let doAllCompaniesHaveError = true;
        let mostRecentError: Error | null = null;
        let isLoading: boolean = false;
        for (let watchlistItem of watchlist) {
            const { name, mnemonic } = watchlistItem;
            const companyDetails = companyDetailsMap[name];
            // Company is loading.
            if (companyDetails === undefined || companyDetails === 'loading') {
                isLoading = true;
                break;
            }
            // Company has error.
            else if (companyDetails instanceof Error) {
                mostRecentError = companyDetails;
                watchlistCompaniesWithErrors.push({
                    company: name,
                    mnemonic,
                    error: companyDetails
                });
                continue;
            }
            // Company has data.
            else {
                doAllCompaniesHaveError = false;
                watchlistCompanyDetailsList.push(companyDetails);
            }
        }

        if (isLoading) {
            return (
                <Row>
                    <Col>
                        <div className='LoadingWrapper'>
                            <h3>Loading...</h3>
                        </div>
                    </Col>
                </Row>
            );
        }

        if (doAllCompaniesHaveError) {
            return (
                <Row>
                    <Col>
                        <div className='ErrorWrapper'>
                            Error: {mostRecentError?.toString()}
                        </div>
                    </Col>
                </Row>
            );
        }

        return (
            <Row>
                <Col xl={4} md={6} xs={12}>
                    {displayWatchlistTable(watchlistCompanyDetailsList, watchlistCompaniesWithErrors)}
                </Col>
                <Col xl={8} md={6} xs={12}>
                    <Row>
                        <Col xl={6} md={12} xs={12}>
                            Watchlist Average
                            {displayWatchlistGemmaqChart(watchlistCompanyDetailsList)}
                        </Col>
                        <Col xl={6} md={12} xs={12}>
                            {displayCeoChairCards(watchlistCompanyDetailsList)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} md={12} xs={12}>
                            {displayGemmaqRatings(watchlistCompanyDetailsList)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Container id='Watchlist'>
            <Row className='PageHeaderRow'>
                <Col xs={12}>
                    <h1 className='PageHeader'>Watchlist</h1>
                </Col>
            </Row>
            {displayWatchlistInfo()}
        </Container>
    );
}

function mapStateToProps({ watchlistReducer, companiesReducer }: RootState) {
    const { watchlist } = watchlistReducer;
    const { companyDetailsMap, companiesList } = companiesReducer;
    return {
        watchlist,
        companyDetailsMap,
        companiesList
    };
}

const connector = connect(mapStateToProps, { requestMultipleCompaniesDetails });
export default connector(Watchlist);