
/* SET LOGIN INFO */
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO';

interface ISetLoginInfoAction {
    type: typeof SET_LOGIN_INFO,
    payload: {
        accessToken: string,
        expiresIn: string,
        idToken: string
    }
}

/* LOG OUT */
export const LOG_OUT = 'LOG_OUT';

interface ILogOutAction {
    type: typeof LOG_OUT
}

/* Export actions */

export type LoginActions =
ISetLoginInfoAction
| ILogOutAction;